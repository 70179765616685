import clone from "just-clone";
import { Table } from "flowbite-react";

import { MoneyFormat, FormatTimeSlot, FindChosenFloorConstraint } from "../../helpers/utils";

const rates = require("../../helpers/community_hall_rates.json");
const globals = require("../../helpers/globals.json");
const Constants = globals.Constants;

const PriceSummary = ({ user_type, event, slots_booked, refundable_deposit_cost }) => {
    if (!slots_booked) {
        return <></>;
    }

    // The deposit is calculated as once per unit (i.e. per time-slot (as per rates.min_time_slot[event]) for events
    // in which (whole_day_booking_required == false) OR, per day for events in which (whole_day_booking_required == true))
    let refundable_deposit_count = 0;

    let total_cost = 0;
    const price_summary_rows = [];
    const whole_day_booking_required = rates.whole_day_booking_required[event];
    for (const [date, time_slots] of Object.entries(slots_booked)) {
        let actual_time_slots = time_slots;
        if (whole_day_booking_required) {
            actual_time_slots = { dummy: clone(time_slots[Constants.BOOKING_START_HOUR]) };
            refundable_deposit_count += 1;
        }

        let print_date = true; // Print the date only once for each time-slot, even if multiple floors have been chosen
        let time_slot_idx = 0; // Each time-slot (as per rates.min_time_slot[event]) has a cost ; not every hour in that time-slot ; this will ensure that the price is calculated accordingly
        for (const [time_slot, floors] of Object.entries(actual_time_slots)) {
            if (!floors.marked) continue;

            if (!whole_day_booking_required) {
                if (time_slot_idx > 0 && time_slot_idx < rates.min_time_slot[event]) {
                    time_slot_idx++;
                    continue;
                } else {
                    time_slot_idx = 1;
                    refundable_deposit_count += 1;
                }
            }

            let print_time_slot = true; // Ensures that the time-slot is only printed once for every time-slot (and not shown for every-floor in that time-slot)
            let floor_cost = 0;
            let actual_floors = {};
            if (event in rates.floor_constraints) {
                const chosen_floor_constraint = FindChosenFloorConstraint(event, floors);
                if (isNaN(chosen_floor_constraint)) continue;

                floor_cost = rates.floor_cost[event][chosen_floor_constraint][user_type];

                // The floor-name will be something like "ground floor + first floor + roof"
                // We will create that name inside the following string. Additionally it will
                // serve as a key for the actual_floors map
                let floor_str = "";

                const constraint = rates.floor_constraints[event][chosen_floor_constraint];
                for (let i = 0; i < constraint.length; i++) {
                    const f = constraint[i];
                    floor_str += rates.floors[f];
                    if (i < constraint.length - 1) floor_str += " + ";
                }

                actual_floors[floor_str] = true;
            } else {
                floor_cost = rates.floor_cost[event][user_type];
                for (const [floor, marked] of Object.entries(floors)) {
                    if (!marked) continue;

                    if (floor in rates.floors) {
                        // Fill in the names of the floors
                        actual_floors[rates.floors[floor]] = true;
                    }
                }
            }

            for (const floor_name of Object.keys(actual_floors)) {
                price_summary_rows.push(
                    <Table.Row key={Math.random()} className="text-black tracking-wide lowercase">
                        <Table.Cell className="px-2">
                            {print_date
                                ? new Date(+date).toLocaleString("en-US", {
                                      month: "long",
                                      day: "numeric",
                                      year: "numeric"
                                  })
                                : ""}
                        </Table.Cell>
                        {!whole_day_booking_required ? (
                            <Table.Cell className="px-2">
                                {print_time_slot ? (
                                    <>
                                        <span>{FormatTimeSlot(time_slot, "00")}</span> - <span>{FormatTimeSlot(parseInt(time_slot) + 3, "59")}</span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Table.Cell>
                        ) : null}
                        <Table.Cell className="px-2">{floor_name}</Table.Cell>
                        <Table.Cell className="px-2">
                            <>{MoneyFormat(floor_cost)}</>
                        </Table.Cell>
                    </Table.Row>
                );

                total_cost += floor_cost;
                print_date = false;
                print_time_slot = false;
            }
        }
    }

    total_cost += refundable_deposit_count * refundable_deposit_cost;
    return (
        <div className="overflow-x-auto">
            <Table>
                <Table.Head>
                    <Table.HeadCell>Date</Table.HeadCell>
                    {!rates.whole_day_booking_required[event] ? <Table.HeadCell>Time-slots</Table.HeadCell> : <></>}
                    <Table.HeadCell>Floors</Table.HeadCell>
                    <Table.HeadCell>Cost</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {price_summary_rows}
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black tracking-wide lowercase text-base">
                        <Table.Cell></Table.Cell>
                        {!rates.whole_day_booking_required[event] ? <Table.Cell></Table.Cell> : <></>}
                        <Table.Cell className="px-2 text-black">Security deposit</Table.Cell>
                        <Table.Cell className="px-2 text-black">
                            {MoneyFormat(refundable_deposit_cost)}&nbsp;&times;&nbsp;{refundable_deposit_count}&nbsp;{whole_day_booking_required ? "day" : "time-slot"}
                            {refundable_deposit_count > 1 ? "s" : ""}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black tracking-wide lowercase text-base">
                        <Table.Cell></Table.Cell>
                        {!rates.whole_day_booking_required[event] ? <Table.Cell></Table.Cell> : <></>}
                        <Table.Cell className="px-2 text-black">total cost</Table.Cell>
                        <Table.Cell className="px-2 text-black">{MoneyFormat(total_cost)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
};

export default PriceSummary;
