import clone from "just-clone";
import { Timestamp } from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";

const rates = require("./community_hall_rates.json");
const globals = require("./globals.json");
const Constants = globals.Constants;

const FindChosenFloorConstraint = (event, floors) => {
    if (!floors || !event) return;

    const floors_marked = [];
    for (const [floor_num, floor_marked] of Object.entries(floors)) {
        const fn = parseInt(floor_num);
        if (floor_marked && !isNaN(fn)) floors_marked.push(fn);
    }

    for (const [constraint_key, constraint] of Object.entries(rates.floor_constraints[event])) {
        if (constraint.length !== floors_marked.length) continue;

        const arr1 = [...constraint].sort();
        const arr2 = [...floors_marked].sort();
        let idx = 0;
        while (idx < arr1.length) {
            if (arr1[idx] !== arr2[idx]) return NaN;
            idx++;
        }

        return constraint_key;
    }

    return NaN;
};

// Modifies 'slots_booked': removes redundant information
function CleanupSlotsObj(slots_booked) {
    for (const [date, time_slots] of Object.entries(slots_booked)) {
        for (const [time_slot, floors] of Object.entries(time_slots)) {
            let keep_time_slot_alive = false;
            for (const [floor_num, is_marked] of Object.entries(floors)) {
                if (!(floor_num in rates.floors)) {
                    delete floors[floor_num];
                    continue;
                }

                if (is_marked) {
                    keep_time_slot_alive = true;
                }
            }

            if (!keep_time_slot_alive) {
                delete time_slots[time_slot];
                continue;
            }
        }

        if (Object.keys(time_slots).length === 0) delete slots_booked[date];
    }

    if (Object.keys(slots_booked).length === 0) return false;
    return true;
}

// s: Remains untouched
// blocked_dates: Modified with new blocked-dates (this can be used to update Firestore)
function BlockDates(s, blocked_dates, block_full_dates) {
    const slots_booked = clone(s);
    const cleanup_status = CleanupSlotsObj(slots_booked);
    if (!cleanup_status) return false;

    for (const [date, time_slots] of Object.entries(slots_booked)) {
        if (!(date in blocked_dates)) blocked_dates[date] = {};
        let num_of_time_slots_blocked = 0;
        for (const [time_slot, floors] of Object.entries(time_slots)) {
            if (!(time_slot in blocked_dates[date])) blocked_dates[date][time_slot] = [];
            for (const [floor_num, is_marked] of Object.entries(floors)) {
                if (is_marked && !blocked_dates[date][time_slot].includes(floor_num)) {
                    blocked_dates[date][time_slot].push(floor_num);
                }
            }

            if (blocked_dates[date][time_slot].length === Object.keys(rates.floors).length) {
                num_of_time_slots_blocked++;
            }
        }

        // console.debug(num_of_time_slots_blocked);
        // console.debug(Constants.BOOKING_END_HOUR - Constants.BOOKING_START_HOUR + 1);
        if (block_full_dates || num_of_time_slots_blocked === Constants.BOOKING_END_HOUR - Constants.BOOKING_START_HOUR + 1) {
            blocked_dates[date]["blocked_completely"] = true;
        }
    }

    return true;
}

// s: Remains untouched
// blocked_dates: Modified with new blocked-dates (this can be used to update Firestore)
function UnblockDates(s, blocked_dates) {
    const slots_booked = clone(s);
    const cleanup_status = CleanupSlotsObj(slots_booked);
    if (!cleanup_status) return false;

    for (const [date, time_slots] of Object.entries(slots_booked)) {
        if (!(date in blocked_dates)) continue;
        delete blocked_dates[date]["blocked_completely"];
        for (const [time_slot, floors] of Object.entries(time_slots)) {
            if (!(time_slot in blocked_dates[date])) continue;
            for (const floor_num of Object.keys(floors)) {
                const idx = blocked_dates[date][time_slot].indexOf(floor_num);
                if (idx > -1) blocked_dates[date][time_slot].splice(idx, 1);
            }

            if (blocked_dates[date][time_slot].length === 0) {
                delete blocked_dates[date][time_slot];
            }
        }

        if (Object.keys(blocked_dates[date]).length === 0) {
            delete blocked_dates[date];
        }
    }

    return true;
}

// Modifies none of the objects passed to it, simply checks whether the slots to be booked ('s') can
// actually be booked, by checking whether they are already booked or not (by going through 'blocked_dates')
function CanBlockDates(s, blocked_dates) {
    const slots_booked = clone(s);
    const cleanup_status = CleanupSlotsObj(slots_booked);
    if (!cleanup_status) return false;

    for (const [date, time_slots] of Object.entries(slots_booked)) {
        if (!(date in blocked_dates)) continue;

        for (const [time_slot, floors] of Object.entries(time_slots)) {
            if (!(time_slot in blocked_dates[date])) continue;

            for (const [floor_num, is_marked] of Object.entries(floors)) {
                if (is_marked && blocked_dates[date][time_slot].includes(floor_num)) {
                    return false;
                }
            }
        }
    }

    return true;
}

const MoneyFormat = (s, html = false) => {
    let amount = `${s}`;
    let formatted_string = amount.slice(-3);
    amount = amount.slice(0, -3);
    while (amount?.length > 0) {
        formatted_string = amount.slice(-2) + "," + formatted_string;
        amount = amount.slice(0, -2);
    }

    if (html) {
        return `&#8377;${formatted_string}`;
    }

    return `₹${formatted_string}`;
};

const DateToString = d => {
    if (d instanceof Timestamp) {
        return DateToString(d.toDate());
    }

    const date = d.getDate().toString();
    const month = (d.getMonth() + 1).toString();
    const date_repr = `${date.padStart(2, "0")}-${month.padStart(2, "0")}-${d.getFullYear()}`;

    return date_repr;
};

const ListOfMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const DateDiff = (d1, d2) => {
    if (d1 instanceof Timestamp && d2 instanceof Timestamp) {
        return DateDiff(d1.toDate(), d2.toDate());
    }

    const date_diff = Math.ceil(Math.abs(d1 - d2) / (1000 * 60 * 60 * 24)) + 1;
    return date_diff;
};

const GetSetOfDates = (d1, d2) => {
    const start_date = d1 <= d2 ? d1 : d2;
    const end_date = start_date === d1 ? d2 : d1;
    let runner = new Date(start_date);
    let set_of_dates = new Set();
    while (runner <= end_date) {
        const epoch = Math.ceil(runner.getTime());
        set_of_dates.add(epoch);
        runner.setDate(runner.getDate() + 1);
    }

    return set_of_dates;
};

const IsEqual = (a, b) => {
    if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    }
};

const GetUserNameFromBookingRefStr = s => {
    if (!s || !s.includes("/")) return null;
    return s.split("/")[0].trim();
};

const GetUserIdFromBookingRefStr = s => {
    if (!s || !s.includes("/")) return null;
    return s.split("/")[1].trim();
};

const FormatTimeSlot = (t, suffix) => {
    return `${t <= 12 ? (t < 10 ? "0" : "") : t - 12 < 10 ? "0" : ""}${t <= 12 ? t : t - 12}:${suffix} ${t < 12 ? "AM" : "PM"}`;
};

const GetServerTime = async () => {
    const functions = getFunctions();
    const get_server_time = httpsCallable(functions, "get_server_time");
    const res = await get_server_time();
    const current_time = res.data.server_time;
    return new Date(current_time);
};

const GetTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export {
    UnblockDates,
    BlockDates,
    CanBlockDates,
    MoneyFormat,
    DateToString,
    DateDiff,
    GetSetOfDates,
    IsEqual,
    ListOfMonths,
    FormatTimeSlot,
    FindChosenFloorConstraint,
    GetUserNameFromBookingRefStr,
    GetUserIdFromBookingRefStr,
    GetServerTime,
    GetTimeZone
};
