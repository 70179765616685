import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "flowbite-react";

function RulesAndRegulations() {
    return (
        <>
            <Helmet>
                <title>FD Block Community Hall rules and regulations | fdblock.org</title>
            </Helmet>
            <div className="flex justify-center">
                <Card className="mt-5 p-2 w-[100%]">
                    <div className="text-3xl text-center tracking-wide">FD Block Community Hall Rules and Regulations</div>
                    <ol className="px-3 list-decimal">
                        <li>
                            <strong>Prohibited Activities</strong>:
                            <ul className="list-disc px-3 py-3">
                                <li>Any situation leading to law and order problems.</li>
                                <li>
                                    Violation of Civic &amp; State Government Rules: &nbsp;Any violation of civic and state government rules will result in
                                    penalties.
                                </li>
                                <li>
                                    Loudspeakers and Sound Systems: &nbsp;Usage of loudspeakers for religious or cultural functions in the community centre or
                                    its adjoining areas is restricted. The sound level must not exceed 85 decibels or as permissible by government regulations
                                    from time to time.
                                </li>
                                <li>
                                    Fireworks and Explosives: The use of fireworks, crackers, explosives, acid, arms, and consumption of alcoholic drinks inside
                                    or outside the premises is not allowed.
                                </li>
                                <li>
                                    Political Activity: &nbsp;The use of the community centre for any political activity in any form is strictly prohibited.
                                </li>
                                <li>Overstay: &nbsp;Overstaying beyond the date and time given will attract a penalty of &#8377;300 per hour.</li>
                                <li>
                                    Unauthorised access to other than the reserved premises at the Community Centre will attract penalty of forfeiture of the
                                    Security Deposit.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Safety Precautions</strong>:
                            <ul className="list-disc px-3 py-3">
                                <li>Fire Hazard Prevention: &nbsp;Decorations or lighting prone to fire hazards are not allowed.</li>
                                <li>Avoidance of Accidents: Free movement to the roof is restricted to avoid accidents.</li>
                                <li>Pollution Control: &nbsp;The use of coal or wood ovens is prohibited to control pollution.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Responsibilities of the Booker</strong>:
                            <ul className="list-disc px-3 py-3">
                                <li>
                                    Key Handover: &nbsp;Keys of the Roof &amp; Room must be handed over to our representative before leaving the hall on the
                                    same day. Violations will be penalised.
                                </li>
                                <li>
                                    Fitting or Fixture Damage: &nbsp;Any damage or misuse of fittings or fixtures will lead to adjustment from the amount of the
                                    security deposit.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Penalty for Unauthorised Use</strong>:
                            <ul className="list-disc px-3 py-3">
                                <li>
                                    A penalty of 100% of the Special Donation will be imposed if the community centre is used for any purpose other than the
                                    declared purpose in the application form. Further bookings by the individual for the Community Centre will not be allowed in
                                    the future.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </Card>
            </div>
        </>
    );
}

export default RulesAndRegulations;
