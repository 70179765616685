import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { TbMoodSadSquint } from "react-icons/tb";
import { Card, Button } from "flowbite-react";

export default function NotFoundPage() {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Page Not Found | fdblock.org</title>
            </Helmet>
            <div className="absolute inset-x-0 inset-y-0 m-auto">
                <Card className="absolute inset-x-0 inset-y-0">
                    <div className="h-80 flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                        <div className="max-w-md flex flex-col gap-y-5">
                            <div className="text-5xl font-dark font-bold flex flex-row gap-2">
                                404
                                <TbMoodSadSquint />
                            </div>
                            <p className="text-2xl md:text-3xl font-light leading-normal">Sorry we couldn't find this page. </p>

                            <Button
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                back to homepage
                            </Button>
                        </div>
                        <div className="max-w-lg"></div>
                    </div>
                </Card>
            </div>
        </>
    );
}
