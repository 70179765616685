import { Link } from "react-router-dom";
import { Footer } from "flowbite-react";

export default function FooterSection({}) {
    return (
        <Footer container={true}>
            <div className="w-full text-center">
                <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                    <Footer.LinkGroup>
                        <Link to="/">
                            <Footer.Link>Home</Footer.Link>
                        </Link>
                        <Link to="/contact">
                            <Footer.Link>Contact</Footer.Link>
                        </Link>
                        <Link to="/about-us">
                            <Footer.Link>About</Footer.Link>
                        </Link>
                        <Link to="/community-hall-rules-and-regulations">
                            <Footer.Link>Community centre rules and regulations</Footer.Link>
                        </Link>
                    </Footer.LinkGroup>
                </div>
                <Footer.Divider />
                <Footer.Copyright by="fdblock.org" year={2024} />
            </div>
        </Footer>
    );
}
