import React, { useContext, useEffect } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { StdContext } from "../context/StdContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function RequireAuth({ require_admin_rights = false }) {
    const { IsAdmin, SignedIn, isFetching } = useContext(StdContext);
    const location = useLocation();

    if (isFetching) {
        return <LoadingAnimation />;
    } else if (require_admin_rights) {
        return <div>{IsAdmin() ? <Outlet /> : <Navigate to={"/404"} />}</div>;
    } else {
        const from_path = encodeURIComponent(`${location.pathname}${location.search}`);
        return <div>{SignedIn() ? <Outlet /> : <Navigate to={location ? `/login?from=${from_path}` : "/login"} replace />}</div>;
    }
}

export default RequireAuth;
