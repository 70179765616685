import React from "react";
import { Helmet } from "react-helmet-async";
import GoogleMapReact from "google-map-react";
import { Card, Button } from "flowbite-react";

function AboutUsPage() {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        <>
            <Helmet>
                <title>About Us | fdblock.org</title>
            </Helmet>
            <div className="mt-4">
                <Card className="text-center">
                    <div className="text-3xl text-center tracking-wide">FD Block Association</div>
                    <div className="text-lg">FD Block is an neighbourhood in Sector III, Salt Lake City, Kolkata, North 24 Parganas District, West Bengal, India</div>
                    <div className="h-96 w-full">
                        <GoogleMapReact bootstrapURLKeys={{ key: "" }} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom} />
                    </div>
                </Card>
            </div>
        </>
    );
}

export default AboutUsPage;
