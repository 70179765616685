import React from "react";
import { Helmet } from "react-helmet-async";

import ViewBooking from "../components/bookings/ViewBooking";

const ViewBookingPage = () => {
    return (
        <>
            <Helmet>
                <title>View Booking | fdblock.org</title>
            </Helmet>
            <ViewBooking />
        </>
    );
};

export default ViewBookingPage;
