import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "flowbite-react";
import ListBookings from "../components/bookings/ListBookings";

const BookingRequestsPage = () => {
    return (
        <div className="w-full">
            <Helmet>
                <title>Approve bookings | fdblock.org</title>
            </Helmet>
            <Card className="p-2 mt-10">
                <h3 className="text-2xl text-right">Booking approval requests</h3>
                <ListBookings list_requests={true} />
            </Card>
        </div>
    );
};

export default BookingRequestsPage;
