import { Timestamp, collection, getDoc, doc, getFirestore, updateDoc, arrayUnion, runTransaction, arrayRemove } from "firebase/firestore";
import { httpsCallable, getFunctions, connectFunctionsEmulator } from "firebase/functions";

import {
    BlockDates,
    UnblockDates,
    CanBlockDates,
    DateToString,
    DateDiff,
    IsEqual,
    GetUserIdFromBookingRefStr,
    GetUserNameFromBookingRefStr,
    GetServerTime
} from "./utils";
import app from "../firebase.config";
import { toast } from "react-toastify";

const rates = require("./community_hall_rates.json");
const globals = require("./globals.json");
const DocNames = globals.DocNames;
const Collections = globals.Collections;
const Constants = globals.Constants;

class Booking {
    static REJECTION_REASON_KEY = "rejection_reason";
    static KEY_BOOKING_REFERENCE_ID = "booking_reference_id";
    static KEY_ID = "id";

    constructor(
        user_id,
        booking_reference_id,
        is_block_member,
        start_date,
        end_date,
        event_type,
        event_description,
        status,
        reference,
        slots_booked,
        refundable_deposit,
        created_on,
        user_email,
        reference_user_email,
        time_zone,
        modified_by = [],
        comments = {},
        id = null,
        approved_by_reference_on = null,
        payment_ref = null
    ) {
        this.user_id = user_id;
        this.booking_reference_id = booking_reference_id;
        this.is_block_member = is_block_member;
        this.start_date = start_date;
        this.end_date = end_date;
        this.event_type = event_type;
        this.event_description = event_description;
        this.status = status;
        this.created_on = created_on;
        this.user_email = user_email;
        this.reference_user_email = reference_user_email;
        this.time_zone = time_zone;
        this.id = id;
        this.modified_by = modified_by;
        this.comments = comments;
        this.reference = reference;
        this.slots_booked = slots_booked;
        this.refundable_deposit = refundable_deposit;
        this.approved_by_reference_on = approved_by_reference_on;
        this.payment_ref = payment_ref;
    }

    GetCreatedOn() {
        return this.created_on;
    }

    GetCreatedOnString() {
        return DateToString(this.created_on);
    }

    GetCostPerDay() {
        return this.cost_per_day;
    }

    GetRefundableDeposit() {
        return this.refundable_deposit;
    }

    GetEventType() {
        return this.event_type;
    }

    GetEventDescription() {
        return this.event_description;
    }

    GetRejectionReason() {
        return this.comments?.rejection_reason;
    }

    GetFloorOption() {
        return this.floor_option;
    }

    IsRequest() {
        return this.status === Constants.STATUS_REQUEST;
    }

    IsRejected() {
        return this.status === Constants.STATUS_REJECTED;
    }

    IsConfirmed() {
        return this.status === Constants.STATUS_CONFIRMED;
    }

    IsUpcoming() {
        const today = new Date();
        return today <= this.end_date;
    }

    IsPast() {
        return !this.IsUpcoming();
    }

    GetStartDateString() {
        return DateToString(this.start_date);
    }

    GetEndDateString() {
        return DateToString(this.end_date);
    }

    GetDuration() {
        return DateDiff(this.start_date, this.end_date);
    }

    GetEventString() {
        return rates.events[this.event_type];
    }

    GetBookingCode() {
        return this.booking_reference_id;
    }

    GetSlotsBooked() {
        return this.slots_booked;
    }

    GetModifiedBy() {
        return this.modified_by;
    }

    GetStatusString() {
        if (this.IsRequest()) return "Request";
        if (this.IsRejected()) return "Rejected";

        const today = new Date();
        if (today <= this.end_date) return "Upcoming";

        return "Past";
    }

    IsSameStartDate(d) {
        if (!(d instanceof Date)) return false;
        return IsEqual(d, this.start_date);
    }

    IsSameEndDate(d) {
        if (!(d instanceof Date)) return false;
        return IsEqual(d, this.end_date);
    }

    IsSameEvent(e) {
        return e === this.event_type;
    }

    IsSameFloorOption(f) {
        return f === this.floor_option;
    }

    IsSameReference(r) {
        return r === this.reference;
    }

    HasReference() {
        return this.reference ? true : false;
    }

    GetReferenceId() {
        return GetUserIdFromBookingRefStr(this.reference);
    }

    GetReferenceName() {
        return GetUserNameFromBookingRefStr(this.reference);
    }

    IsApprovedByBlockReference() {
        return this.approved_by_reference_on ? true : false;
    }

    GetApprovalTimestampStr() {
        return this.approved_by_reference_on ? DateToString(this.approved_by_reference_on) : null;
    }

    GetUserId() {
        return GetUserIdFromBookingRefStr(this.user_id);
    }

    GetUserName() {
        return GetUserNameFromBookingRefStr(this.user_id);
    }

    static CreateUserIdStr(user_name, phone_number) {
        return user_name + " / " + phone_number;
    }

    static async CreateDoc({
        user_id,
        is_block_member,
        start_date,
        end_date,
        event_type,
        event_description,
        status = Constants.STATUS_REQUEST,
        reference,
        slots_booked,
        refundable_deposit,
        user_email,
        reference_user_email,
        time_zone
    }) {
        const db = getFirestore(app);
        let new_booking_data = null;
        let err_msg = null;
        try {
            const blocked_dates_ref = doc(db, Collections.SYSTEM, DocNames.BLOCKED_DATES);
            const bookings_collection = collection(db, Collections.BOOKINGS);
            const booking_request_ref = doc(bookings_collection).withConverter(this.FirestoreConverter);
            const counters_ref = doc(db, Collections.SYSTEM, DocNames.COUNTERS);
            const user_ref = doc(db, Collections.USERS, GetUserIdFromBookingRefStr(user_id));
            const ref_user_ref = reference ? doc(db, Collections.USERS, GetUserIdFromBookingRefStr(reference)) : null;
            const current_time = await GetServerTime();

            await runTransaction(db, async transaction => {
                const blocked_dates_doc = await transaction.get(blocked_dates_ref);
                if (!blocked_dates_doc.exists()) {
                    err_msg = "Cannot create this booking (SYSTEM-ERROR)";
                    console.error("Blocked-dates document does not exist");
                    return;
                }

                // Step 1: Block this range of dates in the system collection (this will also check whether they can be blocked)
                let blocked_dates = blocked_dates_doc.data();
                if (!CanBlockDates(slots_booked, blocked_dates)) {
                    // Does not modify any objects, simply performs checks
                    err_msg = "Could not create booking. Someone may have booked these dates";
                    return;
                }

                if (!BlockDates(slots_booked, blocked_dates)) {
                    err_msg = "Cannot create this booking (SYSTEM-ERROR)";
                    console.error("Could not block dates");
                    return;
                }

                const counters_doc = await transaction.get(counters_ref);
                if (!counters_doc.exists()) {
                    err_msg = "Cannot create this booking (SYSTEM-ERROR)";
                    console.error("Counters document does not exist");
                    return;
                }

                await transaction.set(blocked_dates_ref, blocked_dates);

                // We need to obtain the latest counter value of each respective booking code ("MA", "MFT", etc)
                // and then increase it by 1. This value will be used as a part of the booking reference ID
                const counter = counters_doc.data()["bookings"] + 1;
                await transaction.update(counters_ref, { ["bookings"]: counter });

                const custom_booking_id = "BOOK" + counter.toString().padStart(5, "0");
                new_booking_data = new Booking(
                    user_id,
                    custom_booking_id,
                    is_block_member,
                    start_date,
                    end_date,
                    event_type,
                    event_description,
                    status,
                    reference,
                    slots_booked,
                    refundable_deposit,
                    current_time,
                    user_email,
                    reference_user_email,
                    time_zone
                );

                // Step 2: Create the booking request
                await transaction.set(booking_request_ref, new_booking_data);

                // Step 3: Add the booking request to the user's document
                await transaction.update(user_ref, {
                    bookings: arrayUnion(booking_request_ref)
                });

                if (ref_user_ref) {
                    await transaction.update(ref_user_ref, {
                        booking_approval_requests: arrayUnion(booking_request_ref)
                    });
                }
            });

            new_booking_data["id"] = booking_request_ref.id;
        } catch (err) {
            err_msg = err;
        }

        if (err_msg) return { status: false, message: err_msg };

        //////////////////////////////////////////////////////////////////////////////////
        // Email this booking summary to the user and the reference user (if it exists) //
        //////////////////////////////////////////////////////////////////////////////////

        Booking.SendEmail(new_booking_data, response_data => {
            if (response_data.status && response_data.emails_accepted.includes(user_email)) {
                let email_msg = `The booking summary has been sent to ${user_email}`;
                if (reference_user_email && response_data.emails_accepted.includes(reference_user_email)) {
                    email_msg += ` and ${reference_user_email} (to notify ${reference} about this booking)`;
                }

                toast.success(email_msg);
            } else {
                toast.error("Could not send the booking summary via email");
            }
        });

        return {
            status: true,
            data: new_booking_data
        };
    }

    async DeleteDoc() {
        const db = getFirestore(app);
        let err_msg = null;
        try {
            const blocked_dates_ref = doc(db, Collections.SYSTEM, DocNames.BLOCKED_DATES);
            const booking_ref = doc(db, Collections.BOOKINGS, this.id).withConverter(Booking.FirestoreConverter);
            const user_ref = doc(db, Collections.USERS, GetUserIdFromBookingRefStr(this.user_id));
            const ref_user_ref = this.reference ? doc(db, Collections.USERS, GetUserIdFromBookingRefStr(this.reference)) : null;

            await runTransaction(db, async transaction => {
                const blocked_dates_doc = await transaction.get(blocked_dates_ref);
                if (!blocked_dates_doc.exists()) {
                    console.error("Blocked-dates document does not exist");
                    return;
                }

                let blocked_dates = blocked_dates_doc.data();
                if (!UnblockDates(this.slots_booked, blocked_dates)) {
                    console.error("Could not unblock dates");
                    return;
                }

                await transaction.set(blocked_dates_ref, blocked_dates);

                // Step 1: Remove booking reference from user's document
                await transaction.update(user_ref, {
                    bookings: arrayRemove(booking_ref)
                });

                // Step 2: Removing booking reference from the reference-user's document
                if (ref_user_ref) {
                    await transaction.update(ref_user_ref, {
                        booking_approval_requests: arrayRemove(booking_ref)
                    });
                }

                // Step 3: Delete the booking document
                await transaction.delete(booking_ref);
            });
        } catch (err) {
            err_msg = err;
        }

        if (err_msg) return { status: false, message: err_msg };
        return { status: true };
    }

    async SetBookingStatus(new_status, modifier_id, comments = "") {
        const db = getFirestore(app);
        const current_time = await GetServerTime();
        let err_msg = null;
        let new_modification_obj = {
            status: new_status,
            comments: comments,
            timestamp: current_time
        };

        let updated_booking_obj = {
            status: new_status
        };

        try {
            const blocked_dates_ref = doc(db, Collections.SYSTEM, DocNames.BLOCKED_DATES);
            const booking_ref = doc(db, Collections.BOOKINGS, this.id).withConverter(Booking.FirestoreConverter);
            const modifier_ref = doc(db, Collections.USERS, modifier_id);

            new_modification_obj["modifier"] = modifier_ref;
            updated_booking_obj["modified_by"] = arrayUnion(new_modification_obj);

            await runTransaction(db, async transaction => {
                const blocked_dates_doc = await getDoc(blocked_dates_ref);
                if (!blocked_dates_doc.exists()) {
                    console.error("Blocked-dates document does not exist");
                    err_msg = "Cannot reject this booking (SYSTEM-ERROR)";
                    return;
                }

                let blocked_dates = blocked_dates_doc.data();
                if (new_status === Constants.STATUS_REJECTED) {
                    // About to REJECT this booking
                    if (!UnblockDates(this.slots_booked, blocked_dates)) {
                        err_msg = "Cannot reject this booking (SYSTEM-ERROR)";
                        return;
                    }

                    updated_booking_obj.comments = { ...this.comments, rejection_reason: comments };
                } else if (new_status === Constants.STATUS_CONFIRMED) {
                    // About to modify a REQUEST or a REJECTED booking into a CONFIRMed one
                    const can_block_dates = this.status === Constants.STATUS_REQUEST || CanBlockDates(this.slots_booked, blocked_dates);
                    if (!can_block_dates) {
                        err_msg = "These dates are already taken, this booking cannot be confirmed";
                        return;
                    }

                    const block_dates = BlockDates(this.slots_booked, blocked_dates);
                    if (!block_dates) {
                        err_msg = "Cannot accept this booking (SYSTEM-ERROR)";
                        return;
                    }
                }

                await transaction.set(blocked_dates_ref, blocked_dates);
                await transaction.update(booking_ref, updated_booking_obj);
            });
        } catch (err) {
            err_msg = err;
        }

        if (err_msg) return { status: false, message: err_msg };

        this.status = new_status;
        this.comments = updated_booking_obj.comments;
        this.modified_by.push(new_modification_obj);
        if (this.user_email) {
            Booking.SendEmail(this);
        }

        return { status: true };
    }

    async ApproveBookingRequestFromReference() {
        if (!this.reference) return;

        let err_msg = null;
        let current_time = null;
        try {
            // NOTE: This function approves a booking-request (from the side of the reference user)
            // without doing any sanity checks (whether the current user is actually the reference user, etc)
            current_time = await GetServerTime();
            const db = getFirestore(app);
            const booking_requests_ref = doc(db, Collections.BOOKINGS, this.id).withConverter(Booking.FirestoreConverter);
            await updateDoc(booking_requests_ref, {
                approved_by_reference_on: Timestamp.fromDate(current_time)
            });
        } catch (err) {
            err_msg = err;
        }

        if (err_msg) return { status: false, message: err_msg };

        this.approved_by_reference_on = current_time; // Keep 'this' up to date
        if (this.user_email) {
            Booking.SendEmail(this);
        }

        return { status: true };
    }

    async WriteNewPaymentRefNumber(payment_ref_num) {
        if (!payment_ref_num) return;

        if (payment_ref_num === this.payment_ref) {
            return {
                status: false,
                message: "No change in payment reference number"
            };
        }

        let err_msg = null;
        try {
            const db = getFirestore(app);
            const booking_ref = doc(db, Collections.BOOKINGS, this.id).withConverter(Booking.FirestoreConverter);
            await updateDoc(booking_ref, {
                payment_ref: payment_ref_num
            });
        } catch (err) {
            err_msg = err;
        }

        return { status: err_msg ? false : true, message: err_msg };
    }

    static SendEmail(booking_doc, callback = null) {
        const functions = getFunctions(app);
        const send_email = httpsCallable(functions, "send_confirmation_email");
        // connectFunctionsEmulator(functions, "127.0.0.1", 5001); // ENABLE this when debugging

        send_email({ booking_doc_id: booking_doc.id })
            .then(res => {
                const response_data = res.data;
                if (callback) {
                    callback(response_data);
                } else {
                    if (response_data.emails_accepted.length > 0) {
                        toast.success(
                            `Booking ${
                                booking_doc.status === Constants.STATUS_REQUEST
                                    ? "request"
                                    : booking_doc.status === Constants.STATUS_CONFIRMED
                                    ? "confirmation"
                                    : "rejection"
                            } email(s) have been sent to ${response_data.emails_accepted.join(", ")}`,
                            {
                                hideProgressBar: true
                            }
                        );
                    }

                    if (response_data.emails_rejected.length > 0) {
                        toast.error(
                            `Booking ${
                                booking_doc.status === Constants.STATUS_REQUEST
                                    ? "request"
                                    : booking_doc.status === Constants.STATUS_CONFIRMED
                                    ? "confirmation"
                                    : "rejection"
                            } email(s) could not be sent to ${response_data.emails_rejected.join(", ")}`,
                            {
                                hideProgressBar: true
                            }
                        );
                    }
                }
            })
            .catch(err => {
                let msg = err.data?.message;
                msg = msg ? msg : "Could not send booking information via email";
                toast.error(msg);
            });
    }

    static FirestoreConverter = {
        // Save
        toFirestore: booking => {
            return {
                user_id: booking.user_id,
                booking_reference_id: booking.booking_reference_id,
                is_block_member: booking.is_block_member,
                start_date: Timestamp.fromDate(booking.start_date),
                end_date: Timestamp.fromDate(booking.end_date),
                event_type: booking.event_type,
                event_description: booking.event_description,
                status: booking.status,
                created_on: Timestamp.fromDate(booking.created_on),
                user_email: booking.user_email,
                reference_user_email: booking.reference_user_email,
                time_zone: booking.time_zone,
                modified_by: booking.modified_by?.map(m => ({
                    modifier: m.modifier,
                    status: m.status,
                    comments: m.comments,
                    timestamp: Timestamp.fromDate(m.timestamp)
                })),
                comments: booking.comments,
                reference: booking.reference,
                slots_booked: JSON.stringify(booking.slots_booked),
                refundable_deposit: booking.refundable_deposit,
                approved_by_reference_on: booking.approved_by_reference_on ? Timestamp.fromDate(booking.approved_by_reference_on) : null,
                payment_ref: booking.payment_ref
            };
        },

        // Restore
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return Booking.CopyBookingObject(data, snapshot.id);
        }
    };

    static CopyBookingObject(data, id = null) {
        const data_is_of_type_booking = data instanceof Booking;
        return new Booking(
            data.user_id,
            data.booking_reference_id,
            data.is_block_member,
            data_is_of_type_booking ? data.start_date : data.start_date.toDate(),
            data_is_of_type_booking ? data.end_date : data.end_date.toDate(),
            data.event_type,
            data.event_description,
            data.status,
            data.reference,
            data_is_of_type_booking ? data.slots_booked : JSON.parse(data.slots_booked),
            data.refundable_deposit,
            data_is_of_type_booking ? data.created_on : data.created_on.toDate(),
            data.user_email,
            data.reference_user_email,
            data.time_zone ? data.time_zone : "Asia/Kolkata",
            data.modified_by.map(m => ({
                modifier: m.modifier,
                status: m.status,
                comments: m.comments,
                timestamp: data_is_of_type_booking ? m.timestamp : m.timestamp.toDate()
            })),
            data.comments === undefined ? {} : data.comments,
            data_is_of_type_booking ? data.id : id,
            !data.approved_by_reference_on || data_is_of_type_booking ? data.approved_by_reference_on : data.approved_by_reference_on.toDate(),
            data.payment_ref
        );
    }
}

export default Booking;
