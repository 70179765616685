import React, { useEffect, useState } from "react";
import LoadingAnimation from "../components/LoadingAnimation";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getFirestore, getDocs, collection } from "firebase/firestore";
import app from "../firebase.config.js";
import User from "../helpers/User.js";
import { Button, Card, Badge } from "flowbite-react";
import { Link } from "react-router-dom";
import { FaSearchPlus } from "react-icons/fa";

const globals = require("../helpers/globals.json");
const Collections = globals.Collections;

const columns = [
    {
        field: "profile",
        headerName: "Name",
        width: 250,
        editable: false,
        valueGetter: params => {
            return params.row.name;
        },
        renderCell: value => {
            const user_name = value.value;
            const url = `/admin/users/view-user?uid=${value.row[User.KEY_PHONE_NUM]}`;
            const is_admin = value.row[User.KEY_IS_ADMIN];
            return (
                <Link to={url}>
                    <Button pill color="gray" size="sm">
                        <div className="flex flex-row items-center gap-x-2 justify-center">
                            {user_name}
                            {is_admin ? (
                                <Badge color="success" size="sm">
                                    Admin
                                </Badge>
                            ) : null}
                            <FaSearchPlus />
                        </div>
                    </Button>
                </Link>
            );
        }
    },
    {
        field: "phone_number",
        headerName: "Phone Number",
        width: 150,
        editable: false
    },
    {
        field: "address",
        headerName: "Address",
        width: 150,
        editable: false
    },
    {
        field: "plot",
        headerName: "Plot",
        width: 150,
        editable: false
    },
    {
        field: "is_member",
        headerName: "Is Member",
        width: 150,
        editable: false,
        renderCell: value => {
            return (
                <Badge color={value.value ? "success" : "failure"}>
                    <div className="w-10 text-center text-base lowercase">{value.value ? "Yes" : "No"}</div>
                </Badge>
            );
        }
    },
    {
        field: "is_permanent_member",
        headerName: "Is Lifetime Member",
        width: 150,
        editable: false,
        renderCell: value => {
            return (
                <Badge color={value.value ? "success" : "failure"}>
                    <div className="w-10 text-center text-base lowercase">{value.value ? "Yes" : "No"}</div>
                </Badge>
            );
        }
    }
];

function AllUsersPage() {
    const [usres, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getAllUsers = async () => {
            const db = getFirestore(app);
            let user_data_set = [];
            const user_collection = collection(db, Collections.USERS);
            const users = await getDocs(user_collection);

            let idx = 1;
            users.forEach(doc => {
                const user_obj = doc.data();
                user_data_set.push({
                    ...user_obj,
                    id: user_obj?.id ? user_obj.id : idx
                });

                idx++;
            });

            setUsers(user_data_set);
            setIsLoading(false);
        };

        setIsLoading(true);
        getAllUsers();
    }, []);

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <>
            <Helmet>
                <title>All Users | fdblock.org</title>
            </Helmet>
            <Card className="mt-5 p-2">
                <div>
                    <h1 className="text-3xl text-center tracking-wide">All Users</h1>
                </div>
                <DataGrid
                    rows={usres}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 }
                        }
                    }}
                    pageSizeOptions={[10, 15, 20]}
                    disableRowSelectionOnClick
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                />
            </Card>
        </>
    );
}

export default AllUsersPage;
