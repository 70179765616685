import React, { useState, useEffect } from "react";
import { Spinner } from "flowbite-react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { getFirestore, getDoc, doc } from "firebase/firestore";

// Internal
import app from "../../firebase.config.js";

const globals = require("../../helpers/globals.json");
const Collections = globals.Collections;
const DocNames = globals.DocNames;

export default function DatePicker({ StartDate, SetStartDate, EndDate, SetEndDate, unblockedSetOfDates = null, SetDisableProceed }) {
    const [blocked_dates, SetBlockedDates] = useState(null);
    const [local_state, SetLocalState] = useState([
        {
            startDate: StartDate,
            endDate: EndDate,
            key: "selection"
        }
    ]);

    useEffect(() => {
        SetStartDate(local_state[0]["startDate"]);
        SetEndDate(local_state[0]["endDate"]);
    }, [local_state]);

    const today = new Date();
    const unblock = unblockedSetOfDates !== null ? new Set([...unblockedSetOfDates]) : new Set();

    useEffect(() => {
        const GetBlockedDates = async () => {
            const db = getFirestore(app);
            const bd = doc(db, Collections.SYSTEM, DocNames.BLOCKED_DATES);
            const snap = await getDoc(bd);
            console.warn("Querying data");
            if (snap.exists()) {
                const data = snap.data();
                const dates = [];
                if (data) {
                    Object.keys(data).forEach(d => {
                        if (data[d].blocked_completely) dates.push(d);
                    });
                }

                const filtered_dates = unblock.length === 0 ? dates : dates.filter(d => !unblock.has(d));
                const date_timestamps = filtered_dates.map(d => new Date(+(/* 'd' is a string, convert it to a number */ d)));

                SetBlockedDates(date_timestamps);
            } else {
                SetBlockedDates([]);
            }
        };

        if (blocked_dates === null) GetBlockedDates();
    }, [unblockedSetOfDates]);

    useEffect(() => {
        if (!blocked_dates || !SetDisableProceed) return;
        if (blocked_dates.includes(local_state[0].startDate) || blocked_dates.includes(local_state[0].endDate)) {
            SetDisableProceed(true);
        } else {
            SetDisableProceed(false);
        }
    }, [local_state]);

    if (blocked_dates === null) {
        return <Spinner />;
    } else {
        // For some reason, tightly binding 'onChange' and 'ranges' props of DateRange with the props of THIS
        // component fixed the DateRange view in place (months/years couldn't be changed). The combination of
        // local_state and using its changes to modify the state of the parent component addresses that.
        return (
            <div>
                <DateRange
                    editableDateInputs={true}
                    minDate={today}
                    onChange={item => SetLocalState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={local_state}
                    disabledDates={blocked_dates}
                />
            </div>
        );
    }
}
