import { doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import app from "../firebase.config";

const list = [
    { name: "Asis Kumar Paul", phoneNumber: "+919903901124", plotNumber: "1-1" },
    { name: "Dr. R Shyam", phoneNumber: "+919163268312", plotNumber: "1-2" },
    { name: "Chhanda Paul", phoneNumber: "+919905479070", plotNumber: "1-4" },
    { name: "Amar Bardhan", phoneNumber: "+919433432260", plotNumber: "1-5" },
    { name: "Gautam Datta", phoneNumber: "+918479815372", plotNumber: "1-8" },
    { name: "Pradip Kumar Basak", phoneNumber: "23375624", plotNumber: "2-1" },
    {
        name: "Salil Kumar Ghosh",
        phoneNumber: "+919836518676",
        plotNumber: "2-3"
    },
    {
        name: "Promode Ranjan Dutt",
        phoneNumber: "+919831404738",
        plotNumber: "2-4"
    },
    { name: "Samir Kumar Banerjee", phoneNumber: "23345904", plotNumber: "2-5" },
    { name: "Sitesh Dasgupta", phoneNumber: "+919331048756", plotNumber: "2-6" },
    { name: "Amar Bardhan", phoneNumber: "+919903901124", plotNumber: "2-7" },
    { name: "Malaya Majumdar", phoneNumber: "+918583017572", plotNumber: "3-3" },
    {
        name: "Nirmal Kumar Bardhan",
        phoneNumber: "+919836113083",
        plotNumber: "3-6"
    },
    {
        name: "Asit Kumar Mukherjee",
        phoneNumber: "+918617771966",
        plotNumber: "3-7"
    },
    { name: "Biswanath Bodhak", phoneNumber: "23581332", plotNumber: "4-5" },
    {
        name: "Banshi Dhar Halder",
        phoneNumber: "+919007897390",
        plotNumber: "4-6"
    },
    {
        name: "Subhas Chandra Bose",
        phoneNumber: "+919836299812",
        plotNumber: "4-7"
    },
    {
        name: "Dipankar Adhikary",
        phoneNumber: "+919836436363",
        plotNumber: "4-8"
    },
    { name: "Souritra Biswas", phoneNumber: "+919748234000", plotNumber: "5-1" },
    { name: "Mita Biswas", phoneNumber: "+919748741266", plotNumber: "5-2" },
    {
        name: "Sulochana Chawchharia",
        phoneNumber: "+919903621663",
        plotNumber: "5-3"
    },
    {
        name: "Mrinal Kanti Ganguly",
        phoneNumber: "+919836554272",
        plotNumber: "5-4"
    },
    { name: "Ashim Kshetry", phoneNumber: "+919830016967", plotNumber: "5-5" },
    { name: "Rekha Saha", phoneNumber: "+919830744941", plotNumber: "5-7" },
    { name: "Dilip Banerjee", phoneNumber: "+919836909428", plotNumber: "5-8" },
    {
        name: "Banibrata Banerjee",
        phoneNumber: "+919331377770",
        plotNumber: "6-1"
    },
    { name: "Ashim Guyha Roy", phoneNumber: "+919830092337", plotNumber: "6-2" },
    {
        name: "Ranjan Kumar Chakraborty",
        phoneNumber: "+919831409215",
        plotNumber: "6-4"
    },
    { name: "Mita Roy", phoneNumber: "+919831128123", plotNumber: "6-5" },
    {
        name: "Durga Dutta Agarwala",
        phoneNumber: "+919830141345",
        plotNumber: "6-6"
    },
    {
        name: "Joydeb Chakraborty",
        phoneNumber: "+918420131330",
        plotNumber: "6-8"
    },
    { name: "Debmalya Roy", phoneNumber: "+919836524138", plotNumber: "7-1" },
    {
        name: "Sidhartha Narayan Dutta",
        phoneNumber: "+919051285231",
        plotNumber: "7-3"
    },
    { name: "Ranjit Kumar Ray", phoneNumber: "+919830063546", plotNumber: "7-5" },
    { name: "Ansuman Jana", phoneNumber: "+919163446254", plotNumber: "7-6" },
    { name: "Juthika Kundu", phoneNumber: "+919433398692", plotNumber: "8-1" },
    { name: "Soma Dutta", phoneNumber: "+919818469325", plotNumber: "8-2" },
    { name: "Pradip Tarafder", phoneNumber: "+919836515313", plotNumber: "8-3" },
    { name: "Ansuman Jana", phoneNumber: "", plotNumber: "8-4" },
    {
        name: "Anubha Chatterjee",
        phoneNumber: "+919433392929",
        plotNumber: "8-5"
    },
    { name: "Santanu Biswas", phoneNumber: "+919830596131", plotNumber: "8-6" },
    { name: "Uma Bardhan", phoneNumber: "+919883134177", plotNumber: "8-7" },
    {
        name: "Satyendra Nath Majhi",
        phoneNumber: "+919836694349",
        plotNumber: "8-8"
    },
    { name: "Ashis Kumar Das", phoneNumber: "+919810257237", plotNumber: "9-2" },
    { name: "Anjana Mitra", phoneNumber: "+919830203883", plotNumber: "9-4" },
    { name: "Amita Biswas", phoneNumber: "+919830689226", plotNumber: "9-7" },
    { name: "Sudip Kumar Pan", phoneNumber: "+919051054935", plotNumber: "10-3" },
    {
        name: "Ramendra Kumar Ghosh",
        phoneNumber: "+919433036305",
        plotNumber: "10-6"
    },
    {
        name: "Ravindra Kumar Ladia",
        phoneNumber: "+919831078165",
        plotNumber: "11-1"
    },
    {
        name: "Shravan Kumar Biyani",
        phoneNumber: "+919836122303",
        plotNumber: "11-3"
    },
    {
        name: "Dr. Sukti Mukhopadhyay",
        phoneNumber: "+919830225902",
        plotNumber: "15"
    },
    {
        name: "Mahendra Kumar Jain",
        phoneNumber: "+919339207643",
        plotNumber: "18"
    },
    { name: "Sukrit Banerjee", phoneNumber: "+919330950275", plotNumber: "26" },
    { name: "Suchitra Sengupta", phoneNumber: "+919073379252", plotNumber: "29" },
    { name: "Han Hsien Liao", phoneNumber: "+919830085763", plotNumber: "30-A2" },
    { name: "Pratap Guha", phoneNumber: "+919939152218", plotNumber: "31-GF" },
    {
        name: "Biraj Kumar Guha",
        phoneNumber: "+919830748063",
        plotNumber: "31-FF"
    },
    {
        name: "SubrataBhattacharyya",
        phoneNumber: "+919830013662",
        plotNumber: "35"
    },
    { name: "Lila Bose", phoneNumber: "+919433052317", plotNumber: "37-GF" },
    {
        name: "Sujit Kumar Das",
        phoneNumber: "+919433287204",
        plotNumber: "37-FF"
    },
    {
        name: "Suvaqbrata Bhattacharyya",
        phoneNumber: "+919836513163",
        plotNumber: "38"
    },
    { name: "Uday Kumar Dey", phoneNumber: "+919831085108", plotNumber: "39" },
    {
        name: "Dilip Kumar Rungta",
        phoneNumber: "+919830278847",
        plotNumber: "40"
    },
    { name: "ChuniLal Roychoudhury", phoneNumber: "874615885", plotNumber: "41" },
    { name: "Sanjoy Poddar", phoneNumber: "+919830028940", plotNumber: "43" },
    { name: "Sanjoy Deb", phoneNumber: "+919836662162", plotNumber: "44" },
    {
        name: "Rajendra Prasad Agarwal",
        phoneNumber: "+919748739624",
        plotNumber: "46-SF"
    },
    { name: "Lina Halder", phoneNumber: "23596167", plotNumber: "47" },
    { name: "Anjali Saha", phoneNumber: "23219454", plotNumber: "48" },
    {
        name: "Bancharam Mukherjee",
        phoneNumber: "+919836761523",
        plotNumber: "49"
    },
    { name: "Ajay Kumar Ray", phoneNumber: "+919831711724", plotNumber: "52" },
    { name: "Renuka Bose", phoneNumber: "23340628", plotNumber: "53" },
    { name: "Mamta Saha", phoneNumber: "+919831873562", plotNumber: "55" },
    {
        name: "Om Prakash Sharma",
        phoneNumber: "+919830176851",
        plotNumber: "57-SF"
    },
    {
        name: "Raja Ram Mukherjee",
        phoneNumber: "+919432243184",
        plotNumber: "58-1"
    },
    {
        name: "Satya Priya Dutta Roy",
        phoneNumber: "+919339432684",
        plotNumber: "58-2"
    },
    { name: "Paresh Gupta", phoneNumber: "+919903342180", plotNumber: "58-3" },
    {
        name: "Vinod Kumar Gupta",
        phoneNumber: "+919330953907",
        plotNumber: "58-5"
    },
    { name: "B. Kejriwal", phoneNumber: "+919831090022", plotNumber: "58-6" },
    {
        name: "Raja Ram Mukherjee",
        phoneNumber: "+919432243184",
        plotNumber: "59-1"
    },
    {
        name: "Rekha Bhattacharjee",
        phoneNumber: "+919239112422",
        plotNumber: "59-2"
    },
    { name: "Alok Kumar Das", phoneNumber: "+919831067636", plotNumber: "59-3" },
    {
        name: "Sudhir Kumar Das",
        phoneNumber: "+919836003433",
        plotNumber: "59-4"
    },
    { name: "Amitabha De", phoneNumber: "+919831740852", plotNumber: "59-5" },
    {
        name: "Dilip Kumar Biswas",
        phoneNumber: "+919748914618",
        plotNumber: "59-6"
    },
    { name: "Pradip Majumder", phoneNumber: "+919830453746", plotNumber: "59-7" },
    { name: "Jogesh Ch. De", phoneNumber: "+919433529400", plotNumber: "59-8" },
    { name: "Priti Bhowmik", phoneNumber: "+919831866781", plotNumber: "60-1" },
    {
        name: "Dr. Pratip Majumdar",
        phoneNumber: "+917003815632",
        plotNumber: "60-2"
    },
    {
        name: "Anil Kumar Maitra",
        phoneNumber: "+919432256058",
        plotNumber: "60-6"
    },
    {
        name: "Samarendra Sengupta",
        phoneNumber: "+919433403353",
        plotNumber: "60-7"
    },
    {
        name: "Pramod Ranjan Chanda",
        phoneNumber: "+919007696439",
        plotNumber: "60-8"
    },
    {
        name: "Shukla Bhattacharyya",
        phoneNumber: "+919830510372",
        plotNumber: "61-2"
    },
    {
        name: "Pradip Kumar Mukherjee",
        phoneNumber: "+919331022603",
        plotNumber: "61-3"
    },
    {
        name: "Priti Kana Sarkar",
        phoneNumber: "+919830083514",
        plotNumber: "61-4"
    },
    { name: "Dildara Ahmed", phoneNumber: "+919433055408", plotNumber: "61-5" },
    {
        name: "Subimal Choudhury",
        phoneNumber: "+919433083562",
        plotNumber: "61-6"
    },
    { name: "Tapan Roy", phoneNumber: "+919836624427", plotNumber: "61-7" },
    { name: "Abir Chatterjee", phoneNumber: "+919674610017", plotNumber: "61-8" },
    {
        name: "Alokananda Dasgupta",
        phoneNumber: "+919836041673",
        plotNumber: "62-2"
    },
    {
        name: "Dipak Kumar Sarkar",
        phoneNumber: "+919831460034",
        plotNumber: "62-3"
    },
    {
        name: "Pradip Kumar Som",
        phoneNumber: "+919051877220",
        plotNumber: "62-4"
    },
    { name: "Dildada Ahmed", phoneNumber: "+919433055408", plotNumber: "62-5" },
    { name: "Kajal Majumdar", phoneNumber: "+919831152528", plotNumber: "62-6" },
    {
        name: "Hiranmoy Roy Choudhury",
        phoneNumber: "+919830030434",
        plotNumber: "63-2"
    },
    { name: "Kasturi Champati", phoneNumber: "23214113", plotNumber: "63-4" },
    {
        name: "Subir Ranjan Biswas",
        phoneNumber: "+919831574385",
        plotNumber: "63-5"
    },
    { name: "Subir Banerjee", phoneNumber: "+919831399247", plotNumber: "63-7" },
    {
        name: "Rituparna Sarkar",
        phoneNumber: "+919830428060",
        plotNumber: "63-8"
    },
    {
        name: "Rabindra Narayan Datta",
        phoneNumber: "+919432646410",
        plotNumber: "64-1"
    },
    {
        name: "Asok Bondhopadhyay",
        phoneNumber: "+918910159437",
        plotNumber: "64-2"
    },
    { name: "Manasi Basu", phoneNumber: "+919231837225", plotNumber: "64-3" },
    { name: "Ivy Singh Roy", phoneNumber: "+919051407677", plotNumber: "64-4" },
    {
        name: "Pankaj Kumar Saha",
        phoneNumber: "+919051099306",
        plotNumber: "64-5"
    },
    { name: "Tamajyoti Das", phoneNumber: "+917699802211", plotNumber: "64-7" },
    { name: "Arnab Biswas", phoneNumber: "+919160235817", plotNumber: "64-8" },
    {
        name: "Partha Pratim Das",
        phoneNumber: "+919432305490",
        plotNumber: "65-1"
    },
    { name: "Subhasis Kar", phoneNumber: "+919432646491", plotNumber: "65-2" },
    {
        name: "Samarendra Naht Maity",
        phoneNumber: "+918013130903",
        plotNumber: "65-4"
    },
    {
        name: "Srikrishna Ghosh",
        phoneNumber: "+918420079042",
        plotNumber: "65-5"
    },
    {
        name: "Banamali Batabyal",
        phoneNumber: "+919437801316",
        plotNumber: "65-6"
    },
    {
        name: "Subir Kumar Bhattacharyya",
        phoneNumber: "+919433119751",
        plotNumber: "65-7"
    },
    { name: "Partha De", phoneNumber: "+919433237742", plotNumber: "66-A" },
    { name: "Mukti Sarkar", phoneNumber: "23348953", plotNumber: "66-B" },
    { name: "Kirit Bhusan De", phoneNumber: "916323884", plotNumber: "66-C" },
    { name: "Bhaskar Sanyal", phoneNumber: "+918583087015", plotNumber: "66-F" },
    {
        name: "Pradip Kumar Sanyal",
        phoneNumber: "+919903490022",
        plotNumber: "66-G"
    },
    { name: "Soma Kundu", phoneNumber: "+919830148696", plotNumber: "66-H" },
    {
        name: "Pannalal Mukherjee",
        phoneNumber: "+919830067082",
        plotNumber: "67-1"
    },
    {
        name: "Sukumar Bandhopadhyay",
        phoneNumber: "+919830013784",
        plotNumber: "67-2"
    },
    { name: "Ramen Nag", phoneNumber: "23217878", plotNumber: "67-5" },
    {
        name: "Anil Baran Mahapatra",
        phoneNumber: "+919433185405",
        plotNumber: "67-7"
    },
    { name: "Gputam Das", phoneNumber: "+918583017594", plotNumber: "67-8" },
    {
        name: "Bidyut Kumar Dhar",
        phoneNumber: "+919831177367",
        plotNumber: "68-3"
    },
    {
        name: "Tarun Kumar Pradhan",
        phoneNumber: "+919073367281",
        plotNumber: "68-4"
    },
    { name: "Mithua Bose", phoneNumber: "+919830356232", plotNumber: "68-5" },
    { name: "Kabita Pradhan", phoneNumber: "40094697", plotNumber: "68-6" },
    { name: "Shibani Basu", phoneNumber: "+919830218412", plotNumber: "68-7" },
    { name: "Baby Chatterjee", phoneNumber: "+918420650939", plotNumber: "68-8" },
    {
        name: "Sandhi Mukherjee",
        phoneNumber: "+919830530930",
        plotNumber: "70-A"
    },
    { name: "Md. Abdul Bari", phoneNumber: "+919874557307", plotNumber: "72" },
    {
        name: "Soumyajit Sarkar",
        phoneNumber: "+919836474495",
        plotNumber: "76-GF"
    },
    { name: "Indranil Basu", phoneNumber: "", plotNumber: "76-FF" },
    {
        name: "ChittaranjanBiswas",
        phoneNumber: "+919433900007",
        plotNumber: "77"
    },
    { name: "Sourav Mishra", phoneNumber: "+919748639234", plotNumber: "78" },
    { name: "Asit Mukhopadhyay", phoneNumber: "+918017961488", plotNumber: "79" },
    { name: "Bishwanath Kahali", phoneNumber: "+919433275225", plotNumber: "80" },
    { name: "Sandip Roy", phoneNumber: "+919831869723", plotNumber: "82" },
    { name: "Ashis Halder", phoneNumber: "+919433400031", plotNumber: "83" },
    { name: "Chhayya Choudhury", phoneNumber: "+919433450344", plotNumber: "85" },
    { name: "Niranjan Datta", phoneNumber: "+919163817665", plotNumber: "89" },
    { name: "Amit Kumar Bansal", phoneNumber: "+919830618351", plotNumber: "91" },
    { name: "Anima Mitra", phoneNumber: "+919748834660", plotNumber: "96" },
    { name: "Rohit Seth", phoneNumber: "+919903392601", plotNumber: "100" },
    {
        name: "Subhojit Dutta",
        phoneNumber: "+919143252831",
        plotNumber: "101-GF"
    },
    {
        name: "Satyendra Kumar Mitra",
        phoneNumber: "+919051651616",
        plotNumber: "102"
    },
    {
        name: "Subrata Kumar Das",
        phoneNumber: "+919831309421",
        plotNumber: "103"
    },
    { name: "Partha Ghosh", phoneNumber: "+919836960423", plotNumber: "105" },
    { name: "Nbarun Kumar Mu", phoneNumber: "+919903305765", plotNumber: "106" },
    { name: "Bulu Kundu", phoneNumber: "+919830045844", plotNumber: "107" },
    {
        name: "Priti Ranjan Choudhury",
        phoneNumber: "23586664",
        plotNumber: "108"
    },
    {
        name: "Gopal Chandra Sarkar",
        phoneNumber: "+919163204444",
        plotNumber: "111"
    },
    {
        name: "Dr. Sunil Chandra Saha",
        phoneNumber: "23374449",
        plotNumber: "112"
    },
    { name: "Goutam Chatterjee", phoneNumber: "23374937", plotNumber: "114" },
    { name: "Gopal Mullick", phoneNumber: "+919903422777", plotNumber: "115" },
    {
        name: "Somesh Chandra Chatterjee",
        phoneNumber: "+919830437612",
        plotNumber: "116"
    },
    { name: "Sanjukta Basu", phoneNumber: "+919874020657", plotNumber: "118" },
    { name: "Rita Mukherjee", phoneNumber: "+919874037685", plotNumber: "119" },
    { name: "Arup Bose", phoneNumber: "+919830281333", plotNumber: "120-GF" },
    { name: "Subir Bose", phoneNumber: "+919748117992", plotNumber: "120-FF" },
    { name: "Bhaskar Pal", phoneNumber: "+919831535925", plotNumber: "121" },
    {
        name: "Ramendra Nath Biswas",
        phoneNumber: "+919432135656",
        plotNumber: "122"
    },
    { name: "Mihir Kumar Dhara", phoneNumber: "23216598", plotNumber: "123" },
    { name: "Ashoka Mukherjee", phoneNumber: "+919830629496", plotNumber: "124" },
    {
        name: "Mantu Chandra Bhaowal",
        phoneNumber: "+919836277066",
        plotNumber: "125"
    },
    { name: "Gautom Kukherjee", phoneNumber: "+919830877451", plotNumber: "126" },
    { name: "Arun Kanti Palit", phoneNumber: "+919830120288", plotNumber: "127" },
    {
        name: "Dr. Bhabani Kr. Choudhury",
        phoneNumber: "+919133170645",
        plotNumber: "128"
    },
    { name: "Fita Chakraborty", phoneNumber: "+918981879774", plotNumber: "130" },
    { name: "Tarapada Ghosh", phoneNumber: "+918334987497", plotNumber: "131" },
    { name: "Rajib Das", phoneNumber: "+918583997176", plotNumber: "132" },
    { name: "Sidhartha Bubna", phoneNumber: "40658147", plotNumber: "134" },
    {
        name: "K. K. Chakraborty",
        phoneNumber: "+919433436500",
        plotNumber: "135"
    },
    {
        name: "Kamal Prasad Choudhary",
        phoneNumber: "+919830045581",
        plotNumber: "136"
    },
    {
        name: "Sukumar Choudhury",
        phoneNumber: "+919903702298",
        plotNumber: "137"
    },
    {
        name: "Amal Ranjan Bhattacharya",
        phoneNumber: "+919433773521",
        plotNumber: "138"
    },
    {
        name: "Sivaji Banerjee",
        phoneNumber: "+919611928054",
        plotNumber: "139-GF"
    },
    {
        name: "Tapas Kumar Choudhury",
        phoneNumber: "+919830630190",
        plotNumber: "139-FF"
    },
    { name: "Jayanta Roy", phoneNumber: "+919331023690", plotNumber: "141" },
    { name: "Ajay Kumar Mall", phoneNumber: "23349404", plotNumber: "142" },
    {
        name: "Ramala Chakraborty",
        phoneNumber: "+919830028635",
        plotNumber: "144"
    },
    {
        name: "Soumita Mukherjee",
        phoneNumber: "+919830213202",
        plotNumber: "145"
    },
    {
        name: "Partha Chatterjee",
        phoneNumber: "+918420807547",
        plotNumber: "146"
    },
    { name: "Sumit nandy", phoneNumber: "+919830381948", plotNumber: "151" },
    {
        name: "Dr. Ashok Kr. Das",
        phoneNumber: "+919874111199",
        plotNumber: "152"
    },
    { name: "D. C Biswas", phoneNumber: "+919674090829", plotNumber: "156" },
    { name: "Prabir Ghosh", phoneNumber: "+919830133526", plotNumber: "157" },
    { name: "Siba Prasad Basu", phoneNumber: "+919073794065", plotNumber: "158" },
    {
        name: "Sudhangshu Kumar Biswas",
        phoneNumber: "+919433466608",
        plotNumber: "160"
    },
    {
        name: "Satindra Kumar Bhattachaya",
        phoneNumber: "+919830308457",
        plotNumber: "161"
    },
    { name: "Abhijit Dasgupta", phoneNumber: "+919830951510", plotNumber: "163" },
    { name: "Dr. Kaberi Mitra", phoneNumber: "+919831167033", plotNumber: "164" },
    { name: "Prativa Deb", phoneNumber: "+919433403092", plotNumber: "168" },
    { name: "Madhuparna Paul", phoneNumber: "+919432270014", plotNumber: "169" },
    { name: "Mira Sur", phoneNumber: "+919874948498", plotNumber: "170" },
    { name: "Amalendu Sen", phoneNumber: "+919830454898", plotNumber: "171" },
    {
        name: "Harsha Deb Bhattacharjee",
        phoneNumber: "+918334056332",
        plotNumber: "172"
    },
    {
        name: "Kankan Kumar Ghosh",
        phoneNumber: "+919674609002",
        plotNumber: "173"
    },
    { name: "Amit Bose", phoneNumber: "23348211", plotNumber: "175" },
    { name: "Dr. Asis Kumar", phoneNumber: "+919831100905", plotNumber: "177" },
    { name: "Biplab Roy", phoneNumber: "+919836450021", plotNumber: "181" },
    {
        name: "Angshuman Ghosh Dastidar",
        phoneNumber: "+919432670190",
        plotNumber: "200"
    },
    {
        name: "Naredra Kr Bansal",
        phoneNumber: "+919830144280",
        plotNumber: "207"
    },
    { name: "Subha Dutta Roy", phoneNumber: "+919433416126", plotNumber: "213" },
    { name: "Durga Shankar Dey", phoneNumber: "23594210", plotNumber: "214" },
    { name: "Reba Dasgupta", phoneNumber: "+919836690593", plotNumber: "215-1E" },
    {
        name: "Jayshree Majumdar",
        phoneNumber: "+919830892019",
        plotNumber: "215-W4"
    },
    {
        name: "Dr. Madan Mohan Chaudhuri",
        phoneNumber: "+919830268505",
        plotNumber: "216-1"
    },
    {
        name: "Kamal Kanti Dasgupta",
        phoneNumber: "+919830160539",
        plotNumber: "216-3"
    },
    {
        name: "Birendra Ch. Bhattacharyya",
        phoneNumber: "+919830961395",
        plotNumber: "216-4"
    },
    {
        name: "Sadhana Ganguli",
        phoneNumber: "+919830290206",
        plotNumber: "216-5"
    },
    {
        name: "Mandira Dasgupta",
        phoneNumber: "+919831383376",
        plotNumber: "216-7"
    },
    {
        name: "Channada Sarkar",
        phoneNumber: "+919831050489",
        plotNumber: "216-8"
    },
    {
        name: "Pijush Kanti Basu",
        phoneNumber: "+919830719891",
        plotNumber: "217-5"
    },
    { name: "Rupa Ghosh", phoneNumber: "+919836368586", plotNumber: "219-1" },
    { name: "Sanjukta Basu", phoneNumber: "+919831595881", plotNumber: "219-2" },
    {
        name: "Shantonu Moitra",
        phoneNumber: "+919432020072",
        plotNumber: "219-3"
    },
    {
        name: "Kunal Krishna Chakraborty",
        phoneNumber: "+919432300693",
        plotNumber: "219-4"
    },
    { name: "Uzzwalm Ghosh", phoneNumber: "+919830399292", plotNumber: "219-5" },
    { name: "Anjali Biswas", phoneNumber: "+919830149797", plotNumber: "219-6" },
    {
        name: "Sandhya Purkayastha",
        phoneNumber: "+919874897838",
        plotNumber: "219-7"
    },
    {
        name: "Abhijit Majumdar",
        phoneNumber: "+919433891427",
        plotNumber: "219-8",
        uid: "nicytJ9JR8c0Pw8cvJJS5knSjfp1"
    },
    { name: "Samita Roy", phoneNumber: "+919830220853", plotNumber: "220-1" },
    {
        name: "Saral Sekhar Bose",
        phoneNumber: "+919831241501",
        plotNumber: "220-2"
    },
    { name: "Sanjukta Basu", phoneNumber: "+919831895881", plotNumber: "220-3" },
    {
        name: "Supriya Kumar Moitra",
        phoneNumber: "+919433085034",
        plotNumber: "220-4"
    },
    {
        name: "Girija Sankar Chattopadhyay",
        phoneNumber: "+919830595721",
        plotNumber: "220-6"
    },
    {
        name: "Gurudas Goswami",
        phoneNumber: "+919716826097",
        plotNumber: "220-7"
    },
    { name: "Pritabrata Sen", phoneNumber: "+919831057147", plotNumber: "220-8" },
    {
        name: "Sunita Das (Mira)",
        phoneNumber: "+918017367347",
        plotNumber: "222-4"
    },
    {
        name: "Dr.Susanta Kumar Mitra",
        phoneNumber: "+919331079605",
        plotNumber: "223-4"
    },
    { name: "Arabida Ghose", phoneNumber: "+919432226905", plotNumber: "223-5" },
    {
        name: "Uttam Kumar Roy",
        phoneNumber: "+919433001776",
        plotNumber: "223-6"
    },
    {
        name: "Nemai Chand Pramanik",
        phoneNumber: "+919830290703",
        plotNumber: "223-7"
    },
    { name: "S. K. Aditya", phoneNumber: "+919477356756", plotNumber: "224" },
    { name: "Indrani Banerjee", phoneNumber: "+919836346501", plotNumber: "230" },
    {
        name: "Bimal Kumar Agarwal",
        phoneNumber: "+919831104546",
        plotNumber: "231"
    },
    {
        name: "Chitrita Mukherjee",
        phoneNumber: "+919143719979",
        plotNumber: "234"
    },
    {
        name: "Ranjit Kumar Ghosh",
        phoneNumber: "+919433326420",
        plotNumber: "235"
    },
    {
        name: "Mrinal Kanti Samanta",
        phoneNumber: "+919831001911",
        plotNumber: "236"
    },
    { name: "Anjana Das", phoneNumber: "+919903370622", plotNumber: "237" },
    {
        name: "Abhijit Mukherjee",
        phoneNumber: "+919432361357",
        plotNumber: "239"
    },
    { name: "Biplab Mukherjee", phoneNumber: "+919433470210", plotNumber: "240" },
    { name: "Chunilal Mitra", phoneNumber: "+919830612479", plotNumber: "241" },
    { name: "Sipra Guha Roy", phoneNumber: "+919432073119", plotNumber: "243" },
    {
        name: "Rathindra Mukherjee",
        phoneNumber: "+919831091229",
        plotNumber: "244"
    },
    {
        name: "Rabindra Nath Mukherjee",
        phoneNumber: "+919831623910",
        plotNumber: "245"
    },
    {
        name: "Anu Sanyal Talapatra",
        phoneNumber: "+919831501712",
        plotNumber: "248"
    },
    {
        name: "Dr. (Mrs,) Ashoka Ghosh",
        phoneNumber: "+919831737303",
        plotNumber: "251"
    },
    { name: "Manas Kumar Das", phoneNumber: "+919432493857", plotNumber: "253" },
    { name: "Kalyan Sengupta", phoneNumber: "+919830652504", plotNumber: "256" },
    {
        name: "Nitya Ranjan Rouchoudhury",
        phoneNumber: "+918617790993",
        plotNumber: "258"
    },
    { name: "Byomkesh Ganguly", phoneNumber: "+919477278326", plotNumber: "262" },
    {
        name: "Sanat Kumar Kundu",
        phoneNumber: "+918334915551",
        plotNumber: "266-GF"
    },
    {
        name: "Soumyadeep Kundu",
        phoneNumber: "+916145241624",
        plotNumber: "266-FF"
    },
    {
        name: "Sangamitra Kundu",
        phoneNumber: "+919432746478",
        plotNumber: "266-SF"
    },
    { name: "Arun Kumar De", phoneNumber: "+919831578672", plotNumber: "269" },
    { name: "Subrata |Saha", phoneNumber: "+919433898129", plotNumber: "271" },
    {
        name: "Nanda Dulal Banik",
        phoneNumber: "+918961461013",
        plotNumber: "272"
    },
    { name: "Sanjit Dey", phoneNumber: "+919830211512", plotNumber: "273-FF" },
    { name: "Avijit Dey", phoneNumber: "+919433238664", plotNumber: "273-SF" },
    {
        name: "Sanjib Chandra Saha",
        phoneNumber: "+919830015074",
        plotNumber: "274"
    },
    {
        name: "Bipul Chandra Roy",
        phoneNumber: "+919830703952",
        plotNumber: "276"
    },
    {
        name: "Mahesh Kumar Agarwal",
        phoneNumber: "+919830040221",
        plotNumber: "277-GF"
    },
    { name: "Jayanta Jash", phoneNumber: "+917044366900", plotNumber: "277-FF" },
    {
        name: "Ajoy Kumar Sen",
        phoneNumber: "+919831252580",
        plotNumber: "277-SF"
    },
    { name: "Debjani Ray", phoneNumber: "+919702033881", plotNumber: "278-GF" },
    { name: "Bhakti Chakraborty", phoneNumber: "23218602", plotNumber: "278-FF" },
    {
        name: "Samir Kumar Bhattacharya",
        phoneNumber: "+919830109630",
        plotNumber: "279-GF"
    },
    {
        name: "Kallol Chatterjee",
        phoneNumber: "+919830661089",
        plotNumber: "279-FF"
    },
    { name: "Dipa Roy", phoneNumber: "+919830105001", plotNumber: "279-SF" },
    {
        name: "Raj Kumar Jaiswal",
        phoneNumber: "+919830057966",
        plotNumber: "280"
    },
    {
        name: "Narayan Prasad Agaewal",
        phoneNumber: "+919830084837",
        plotNumber: "281"
    },
    {
        name: "Parmananda Agarwal",
        phoneNumber: "+919339295523",
        plotNumber: "282"
    },
    {
        name: "Pradip Kumar Jain",
        phoneNumber: "+919830050423",
        plotNumber: "282-SF"
    },
    { name: "Sanjay Maheswari", phoneNumber: "+919830027447", plotNumber: "283" },
    { name: "Debojyoti Mitra", phoneNumber: "+919433686940", plotNumber: "284" },
    { name: "Ratan Modi", phoneNumber: "+919331016831", plotNumber: "284-FF" },
    { name: "G. P. Sarkar", phoneNumber: "+919830062626", plotNumber: "285" },
    { name: "B.R Biswas", phoneNumber: "23592286", plotNumber: "286-FF" },
    {
        name: "Naresh Chandra Saha",
        phoneNumber: "+919831575340",
        plotNumber: "287"
    },
    { name: "Vikash Jalan", phoneNumber: "983055800", plotNumber: "288-FF" },
    { name: "Hiralal Jalan", phoneNumber: "+919339705193", plotNumber: "288-SF" },
    { name: "Akash Jalan", phoneNumber: "+919831046111", plotNumber: "288-TF" },
    {
        name: "Dibyendu Chakraborty",
        phoneNumber: "+919830020148",
        plotNumber: "289-FF"
    },
    {
        name: "Tapan Kumar Sil",
        phoneNumber: "+918240795193",
        plotNumber: "289-SF"
    },
    { name: "Abhay Khanna", phoneNumber: "+919748031649", plotNumber: "290-GF" },
    { name: "Ramji Khanna", phoneNumber: "40607190", plotNumber: "290-FF" },
    {
        name: "Ajay Kumar Khanna",
        phoneNumber: "+919123041206",
        plotNumber: "290-SF"
    },
    { name: "Dr. Kanchan Mandal", phoneNumber: "23582194", plotNumber: "291-FF" },
    {
        name: "Kaustav Mandal",
        phoneNumber: "+917044474001",
        plotNumber: "291-SF"
    },
    { name: "Bimal Chandra Das", phoneNumber: "23344033", plotNumber: "292-GF" },
    { name: "Tarun Kumar Bag", phoneNumber: "23216675", plotNumber: "292-SF" },
    { name: "Niranjan Roy", phoneNumber: "+919903932565", plotNumber: "293-GF" },
    {
        name: "Jyotirmoy Sikdar",
        phoneNumber: "+919477238656",
        plotNumber: "293-FF"
    },
    {
        name: "Biswajit Sikdar",
        phoneNumber: "+919831124716",
        plotNumber: "293-SF"
    },
    {
        name: "Tapan Kumar Acharya",
        phoneNumber: "+919874923674",
        plotNumber: "294-GF"
    },
    {
        name: "Amar Nath Ghosh",
        phoneNumber: "+919903037801",
        plotNumber: "294-FF"
    },
    { name: "Pradip Kundu", phoneNumber: "+919717649109", plotNumber: "295" },
    { name: "Pragati Jalan", phoneNumber: "+919831135524", plotNumber: "296" },
    {
        name: "Tushar Kanti Goon",
        phoneNumber: "+919433018554",
        plotNumber: "297-FF"
    },
    { name: "Gupteswar Prasad", phoneNumber: "+919830043139", plotNumber: "298" },
    { name: "Avinash Agarwal", phoneNumber: "+919830011465", plotNumber: "299" },
    {
        name: "Bithaka Bhowmik",
        phoneNumber: "+919007183349",
        plotNumber: "300-GF"
    },
    { name: "Arun Agarwal", phoneNumber: "+919831030763", plotNumber: "303-FF" },
    {
        name: "Gopesh Chaktaborty",
        phoneNumber: "+919830929784",
        plotNumber: "304-GF"
    },
    {
        name: "Dhirendra nath Biswas",
        phoneNumber: "+919836161551",
        plotNumber: "304-FF"
    },
    {
        name: "Rabindra Nath Gangopadhyay",
        phoneNumber: "+919830084645",
        plotNumber: "305-GF"
    },
    { name: "Ipsita Biswas", phoneNumber: "+919830353896", plotNumber: "305-FF" },
    { name: "Sankari Nath", phoneNumber: "+918981070542", plotNumber: "306-GF" },
    {
        name: "Surendra Kandoi",
        phoneNumber: "+919830058373",
        plotNumber: "306-FF"
    },
    {
        name: "Rajesh Agarwal",
        phoneNumber: "+919831100276",
        plotNumber: "306-SF"
    },
    {
        name: "Jugal Kishore Mondal",
        phoneNumber: "+919432590528",
        plotNumber: "307"
    },
    {
        name: "Susanta Kumar Gupta",
        phoneNumber: "+919331021063",
        plotNumber: "308"
    },
    {
        name: "Dilip Kumar Majumdar",
        phoneNumber: "+919933333148",
        plotNumber: "310"
    },
    { name: "Manju Ghosh", phoneNumber: "+919831815181", plotNumber: "311" },
    {
        name: "Namita Bhattacharjee",
        phoneNumber: "+919474409007",
        plotNumber: "312"
    },
    { name: "Ranjit Choudhury", phoneNumber: "+919831455755", plotNumber: "313" },
    {
        name: "Sisir Mukhopadyyay",
        phoneNumber: "+919748060656",
        plotNumber: "314"
    },
    { name: "Dr. Anirban Maji", phoneNumber: "+919830708020", plotNumber: "316" },
    {
        name: "Tapas Kumar Mullick",
        phoneNumber: "+919836991319",
        plotNumber: "317"
    },
    {
        name: "Kshitish Chandra Saha",
        phoneNumber: "+919433047912",
        plotNumber: "318"
    },
    { name: "Dr. Prasanta Acharya", phoneNumber: "23375098", plotNumber: "320" },
    {
        name: "Bagrang lal Agarwal ",
        phoneNumber: "+919339219609",
        plotNumber: "321-GF"
    },
    {
        name: "Rajendra Prasad Murarka",
        phoneNumber: "+919330138860",
        plotNumber: "321-FF"
    },
    {
        name: "Pramod Agarwal",
        phoneNumber: "+919831231332",
        plotNumber: "321-SF"
    },
    {
        name: "Sanat Kumar Chakraborti",
        phoneNumber: "+919433380945",
        plotNumber: "322"
    },
    {
        name: "Snehansu Sinha Roy",
        phoneNumber: "+919831785736",
        plotNumber: "323-GF"
    },
    {
        name: "Santanu Sinha Roy",
        phoneNumber: "+919433064930",
        plotNumber: "323-FF"
    },
    { name: "Debasish Lahiri", phoneNumber: "+918240530383", plotNumber: "324" },
    {
        name: "Pankaj Kumar Banik",
        phoneNumber: "+918902252196",
        plotNumber: "337"
    },
    {
        name: "Nripendra Chandra Brahma",
        phoneNumber: "23345864",
        plotNumber: "340"
    },
    { name: "Sharda Gupta", phoneNumber: "+919831829230", plotNumber: "344-GF" },
    {
        name: "Anil Choudhury",
        phoneNumber: "+919831120402",
        plotNumber: "344-FF"
    },
    {
        name: "Asim Kumar Bhattacharyya",
        phoneNumber: "+919433064900",
        plotNumber: "345"
    },
    { name: "Jayanti Baral", phoneNumber: "+919674310006", plotNumber: "346-GF" },
    {
        name: "Dilip Chand Baral",
        phoneNumber: "+919331021785",
        plotNumber: "346-FF"
    },
    {
        name: "Swaroop Chand Baral",
        phoneNumber: "+918296540092",
        plotNumber: "346-SF"
    },
    {
        name: "Sanat Kumar Agarwal(T)",
        phoneNumber: "+919830080551",
        plotNumber: "347-FF"
    },
    {
        name: "Dr. Ravi Sinha",
        phoneNumber: "+919830355467",
        plotNumber: "347-SF"
    },
    {
        name: "Mangal Singh Parihar(T)",
        phoneNumber: "+919649595981",
        plotNumber: "348-GF"
    },
    {
        name: "Ajit Kumar Saha",
        phoneNumber: "+919432300643",
        plotNumber: "348-FF"
    },
    {
        name: "Nemai Chandra Paul",
        phoneNumber: "+919874733099",
        plotNumber: "349"
    },
    { name: "Ratan Kumar Modi", phoneNumber: "+919331016831", plotNumber: "351" },
    {
        name: "Dr. Sipra Bhattacharjee",
        phoneNumber: "+919433134998",
        plotNumber: "352"
    },
    {
        name: "Shiladitya Roy Choudhury",
        phoneNumber: "+919831571222",
        plotNumber: "353"
    },
    {
        name: "Jahar Lal Bandopadhyay",
        phoneNumber: "23376696",
        plotNumber: "357"
    },
    { name: "Sujit Kumar Paul", phoneNumber: "+919332610934", plotNumber: "358" },
    { name: "Sukdeb Kundu", phoneNumber: "23372100", plotNumber: "359" },
    {
        name: "Dr. Purabi Chatterjee",
        phoneNumber: "+919836903237",
        plotNumber: "360"
    },
    { name: "Rita De", phoneNumber: "23580315", plotNumber: "361" },
    { name: "Tarak Nath Mitra", phoneNumber: "23580428", plotNumber: "362" },
    {
        name: "Kailash Chand Toshniwal",
        phoneNumber: "+919331015820",
        plotNumber: "363"
    },
    {
        name: "Somik Kumar Chatterjee",
        phoneNumber: "+919830171046",
        plotNumber: "364"
    },
    {
        name: "Ashok Kumar Chatterjee",
        phoneNumber: "23340902",
        plotNumber: "365"
    },
    { name: "Bhaskar Mitra", phoneNumber: "+919433231638", plotNumber: "366" },
    { name: "Bama Dasgupta", phoneNumber: "+919674220570", plotNumber: "367-GF" },
    {
        name: "Asish Kumar Sen",
        phoneNumber: "+919831207581",
        plotNumber: "367-FF"
    },
    { name: "B. K. Biswas", phoneNumber: "+919831764368", plotNumber: "368" },
    { name: "Sib Nath Basu", phoneNumber: "+919432270110", plotNumber: "369" },
    { name: "Debasis Basu", phoneNumber: "+919830492550", plotNumber: "370" },
    {
        name: "Anand Kumar Pasari",
        phoneNumber: "+919831070258",
        plotNumber: "371"
    },
    { name: "Haradhan Sashmal", phoneNumber: "+919830109574", plotNumber: "373" },
    { name: "Jayanta Rudra", phoneNumber: "+919831067571", plotNumber: "379-GF" },
    {
        name: "Jyotirmoy Rudra",
        phoneNumber: "+919831606195",
        plotNumber: "379-FF"
    },
    {
        name: "Sandip Kumar Bhadra",
        phoneNumber: "+919433050546",
        plotNumber: "379-SF"
    },
    {
        name: "Pawan Kumar Agaewal",
        phoneNumber: "+919830022575",
        plotNumber: "383-GF"
    },
    {
        name: "Satyanarayan Agarwal",
        phoneNumber: "+919230581223",
        plotNumber: "383-FF"
    },
    {
        name: "Vijay Kumar Agarwal",
        phoneNumber: "+919831350341",
        plotNumber: "383-SF"
    },
    { name: "Dipali Saha Roy", phoneNumber: "+919143199346", plotNumber: "384" },
    { name: "Asoke Kumar Das", phoneNumber: "+919007762871", plotNumber: "385" },
    { name: "Dilip Kumar Ray", phoneNumber: "23585578", plotNumber: "386" },
    {
        name: "H. S. Bhanja Choudhury",
        phoneNumber: "+918420758807",
        plotNumber: "387"
    },
    {
        name: "Saibal Chakraborty",
        phoneNumber: "+919433191099",
        plotNumber: "388"
    },
    { name: "Sandhya Dalabar", phoneNumber: "+919903873847", plotNumber: "391" },
    {
        name: "Ram  Chandra Jaiswal",
        phoneNumber: "+919883012206",
        plotNumber: "392"
    },
    {
        name: "Dr. Birendra Kumar Debey",
        phoneNumber: "+919836003745",
        plotNumber: "393-FF"
    },
    {
        name: "Shailendra Kumar Dubey",
        phoneNumber: "+919830046729",
        plotNumber: "393-SF"
    },
    { name: "Uma Choudhuri", phoneNumber: "23376573", plotNumber: "395" },
    {
        name: "Mihir Kumar Ganguly",
        phoneNumber: "+919433107029",
        plotNumber: "398-FF"
    },
    {
        name: "Manish Kumar Ganguly",
        phoneNumber: "+919674013432",
        plotNumber: "398-SF"
    },
    {
        name: "Ranjit Talapatra",
        phoneNumber: "+919831095162",
        plotNumber: "401-FF"
    },
    {
        name: "Sadhan Kumar Mukherjee",
        phoneNumber: "869777703",
        plotNumber: "405"
    },
    {
        name: "Anju Jhunjhunwala",
        phoneNumber: "+919830029556",
        plotNumber: "406"
    },
    { name: "Gayatri Ghosh", phoneNumber: "+918420965792", plotNumber: "409" },
    {
        name: "Subrata Majumdar",
        phoneNumber: "+919433128212",
        plotNumber: "415-GF"
    },
    {
        name: "Debasish Majumdar",
        phoneNumber: "+919830235086",
        plotNumber: "415-FF"
    },
    { name: "Deepa Biswas", phoneNumber: "+919830038592", plotNumber: "416-2" },
    { name: "Moheul Islam", phoneNumber: "+919831217470", plotNumber: "416-5" },
    { name: "Tariq Islam", phoneNumber: "+919830582808", plotNumber: "416-6" },
    { name: "Saukat Ali", phoneNumber: "+919831436147", plotNumber: "416-7/" },
    {
        name: "Prabuddha Majumdar",
        phoneNumber: "+917624015173",
        plotNumber: "417-1"
    },
    {
        name: "Tapan Kumar Nag",
        phoneNumber: "+919433037544",
        plotNumber: "417-2"
    },
    {
        name: "Sounitra Lahiri",
        phoneNumber: "+919831016147",
        plotNumber: "417-3"
    },
    {
        name: "Soumendra Nath Bera",
        phoneNumber: "+919880469974",
        plotNumber: "417-4"
    },
    {
        name: "Malay Kanti Bhattacharya",
        phoneNumber: "+919433375418",
        plotNumber: "417-5"
    },
    {
        name: "Tapas Kumar Chatterjee",
        phoneNumber: "+919433074730",
        plotNumber: "417-6"
    },
    { name: "Sipra Basu", phoneNumber: "+919830081523", plotNumber: "417-7" },
    { name: "Pran Gobindo Sarkar", phoneNumber: "46018777", plotNumber: "418-1" },
    {
        name: "Ashok Kumar Agarwala",
        phoneNumber: "+918373079977",
        plotNumber: "418-7"
    },
    {
        name: "Sharmila Chatterjee",
        phoneNumber: "+919051787153",
        plotNumber: "418-8"
    },
    {
        name: "Purnima Adhikary",
        phoneNumber: "+919433021143",
        plotNumber: "419-7"
    },
    {
        name: "Paritosh Kumar Halder",
        phoneNumber: "+919830804007",
        plotNumber: "421-1"
    },
    {
        name: "Dr. Sudipta Sekhar Das",
        phoneNumber: "+919641949552",
        plotNumber: "421-3"
    },
    { name: "Amitava Dutta", phoneNumber: "+919830982044", plotNumber: "422-4" },
    {
        name: "Sekhar Bhattacharya",
        phoneNumber: "+916290407764",
        plotNumber: "423-1"
    },
    {
        name: "Awani Ranjan Prasad",
        phoneNumber: "+918100001018",
        plotNumber: "423-2"
    },
    { name: "Bani Sengupta", phoneNumber: "+919477922098", plotNumber: "425" },
    { name: "Sree Ram Murarka", phoneNumber: "+919836069429", plotNumber: "429" },
    {
        name: "Ved Prakash Agarwal",
        phoneNumber: "+919331872078",
        plotNumber: "433"
    },
    {
        name: "Dr. Satyabrata Datta",
        phoneNumber: "+919830143026",
        plotNumber: "438"
    },
    { name: "Prabha Mishra", phoneNumber: "+919830144613", plotNumber: "444" },
    { name: "Tania Islam", phoneNumber: "+919007647035", plotNumber: "451-A1" },
    {
        name: "Sambhu Nath Nan",
        phoneNumber: "+919432645862",
        plotNumber: "451-A4"
    },
    {
        name: "Satyendra Nath Gupta",
        phoneNumber: "+919830707685",
        plotNumber: "451-A5"
    },
    { name: "Sonali Islam", phoneNumber: "+919830380609", plotNumber: "451-B4" },
    {
        name: "Dhiresh Ch. Bhattacharya",
        phoneNumber: "+919133033972",
        plotNumber: "452-A3"
    },
    { name: "Indrani Bagchi", phoneNumber: "23348164", plotNumber: "452-A6" },
    { name: "Mamta Ghosh", phoneNumber: "+919432386309", plotNumber: "452-A8" },
    {
        name: "Bhaskar Chandra Latek",
        phoneNumber: "+919433007070",
        plotNumber: "452-E"
    },
    { name: "Rathin Saha", phoneNumber: "+919432231134", plotNumber: "454-4" },
    { name: "Tanuka Roy", phoneNumber: "+919830025424", plotNumber: "454-6" },
    {
        name: "Pranabendu Deb",
        phoneNumber: "+919007950576",
        plotNumber: "454-12"
    },
    {
        name: "Ranjit Kumar Mukherjee",
        phoneNumber: "+919830914606",
        plotNumber: "456-5"
    },
    {
        name: "Baidyanath Nath Basak",
        phoneNumber: "+918334057307",
        plotNumber: "457-7"
    },
    {
        name: "Pranab Kumar Biswas",
        phoneNumber: "+919830017342",
        plotNumber: "458-1"
    },
    {
        name: "Goputam Bhattacharya",
        phoneNumber: "+919831683475",
        plotNumber: "458-4"
    },
    {
        name: "Amalendu B Mahapatra",
        phoneNumber: "23594888",
        plotNumber: "458-6"
    },
    {
        name: "Sankar Chakraborty",
        phoneNumber: "+919831970216",
        plotNumber: "458-7"
    },
    {
        name: "Bimal Kumar Bharat",
        phoneNumber: "+919830085006",
        plotNumber: "459-3"
    },
    { name: "N. L. Poddar", phoneNumber: "+919830034874", plotNumber: "459-5" },
    {
        name: "Jagadish Prasad Jayswal",
        phoneNumber: "+919831111123",
        plotNumber: "460-3"
    },
    {
        name: "Dilip Kumar Gupta",
        phoneNumber: "+919883218453",
        plotNumber: "460-8"
    },
    {
        name: "Kamalika Chowdhury",
        phoneNumber: "+919831023814",
        plotNumber: "461-1A"
    },
    { name: "Dilip Rakshit", phoneNumber: "+918902198999", plotNumber: "461-2A" },
    {
        name: "Sujit Kumar Ghosh",
        phoneNumber: "+919830106504",
        plotNumber: "461-2B"
    },
    { name: "Soma Palit", phoneNumber: "23580456", plotNumber: "461-3A" },
    {
        name: "Kamal Choudhury",
        phoneNumber: "+919831023814",
        plotNumber: "461-3B"
    },
    { name: "K Rajeev Lal", phoneNumber: "+919831132461", plotNumber: "461-4A" },
    {
        name: "Sanjukta Ghosh(Mukherjee)",
        phoneNumber: "+919051823070",
        plotNumber: "461-4B"
    },
    { name: "Purnima Ghatak", phoneNumber: "23370328", plotNumber: "462-2" },
    { name: "Gautam Ghosh", phoneNumber: "+919830550194", plotNumber: "462-3" },
    {
        name: "Pradip Kumar Gupta",
        phoneNumber: "+919830281020",
        plotNumber: "463-6"
    },
    {
        name: "Arup Ranjan Pradhan",
        phoneNumber: "+919875591070",
        plotNumber: "464-4"
    },
    {
        name: "Gangadhar Mondal",
        phoneNumber: "+919432288234",
        plotNumber: "464-8"
    },
    {
        name: "Dr. Sukanta Biswas",
        phoneNumber: "+918420092910",
        plotNumber: "465-1"
    },
    { name: "Sipra Biswas", phoneNumber: "+919433994355", plotNumber: "465-2" },
    {
        name: "Dr. Sima Pramanik",
        phoneNumber: "+919474678847",
        plotNumber: "465-4"
    },
    {
        name: "Dr. Mohammad Kudrat",
        phoneNumber: "+919007779805",
        plotNumber: "465-7"
    },
    { name: "Arup Mukherjee", phoneNumber: "+919903278627", plotNumber: "465-8" },
    {
        name: "Monoranjam Adhikari",
        phoneNumber: "+919874325560",
        plotNumber: "466-1"
    },
    {
        name: "Madhusudan Kundu",
        phoneNumber: "+918967962677",
        plotNumber: "466-2"
    },
    {
        name: "Birendro Nath Banerjee",
        phoneNumber: "+919874199063",
        plotNumber: "466-3"
    },
    { name: "Susital Mondal", phoneNumber: "+919830986702", plotNumber: "466-5" },
    { name: "Sujit Banerjee", phoneNumber: "+919433330203", plotNumber: "466-8" },
    {
        name: "Prabhat Kumar Ghosh",
        phoneNumber: "+919007919328",
        plotNumber: "466-9"
    },
    {
        name: "Arindam Adhikari",
        phoneNumber: "+919830186740",
        plotNumber: "466-11"
    },
    { name: "Bimalendu Basu", phoneNumber: "+919830212578", plotNumber: "467-1" },
    {
        name: "S. D Purakayastha",
        phoneNumber: "+919051280454",
        plotNumber: "468-1"
    },
    { name: "Ajay Mukherjee", phoneNumber: "+919830701110", plotNumber: "468-3" },
    { name: "Dibyajyoti Sen", phoneNumber: "+919434345754", plotNumber: "468-4" },
    { name: "Amal Kanti Das", phoneNumber: "+918910512550", plotNumber: "469-2" },
    {
        name: "Pradip Ranjan Bhowmik",
        phoneNumber: "+919433766467",
        plotNumber: "469-4"
    },
    {
        name: "Swaraj Sadhan Kar",
        phoneNumber: "+919433403189",
        plotNumber: "469-5"
    },
    {
        name: "Dibyendu Nath Chakraborty",
        phoneNumber: "+918902177120",
        plotNumber: "469-6"
    },
    {
        name: "Anup Kumar Biswas",
        phoneNumber: "+919830066716",
        plotNumber: "469-7"
    },
    { name: "Ranjit Bhattacharje", phoneNumber: "", plotNumber: "470-2" },
    { name: "Dilip Basu Roy", phoneNumber: "+919433161782", plotNumber: "470-6" },
    {
        name: "Ranjit Bhattacharjee",
        phoneNumber: "+919830997543",
        plotNumber: "470-7"
    },
    { name: "Putul Roy", phoneNumber: "+917980603206", plotNumber: "470-8" },
    { name: "Dr. Mira Saha", phoneNumber: "+919836026954", plotNumber: "470-10" },
    {
        name: "Gautam Mukherjee",
        phoneNumber: "+919330983998",
        plotNumber: "470-11"
    },
    {
        name: "Susanta Kumar Sen",
        phoneNumber: "+919051046588",
        plotNumber: "472-1"
    },
    { name: "Ashim Kumar Das", phoneNumber: "23345813", plotNumber: "472-2" },
    { name: "Kalyan Kumar Bagchi", phoneNumber: "23587552", plotNumber: "472-3" },
    {
        name: "Bijan Kumar Kundu",
        phoneNumber: "97488860872",
        plotNumber: "472-4"
    },
    {
        name: "Surachana Banerjee",
        phoneNumber: "+919742033324",
        plotNumber: "472-5"
    },
    {
        name: "Suniti Samaddar",
        phoneNumber: "+919903754140",
        plotNumber: "472-6"
    },
    {
        name: "Dyuti Kumar Basu",
        phoneNumber: "+918777432946",
        plotNumber: "472-7"
    },
    {
        name: "Manoj Kumar Nandi",
        phoneNumber: "+919903214716",
        plotNumber: "472-8"
    },
    {
        name: "Test",
        phoneNumber: "+918343845909",
        plotNumber: "4"
    }
];

export const addToFirebase = async () => {
    const db = getFirestore(app);
    list.forEach(async user_data => {
        const user = {
            name: user_data.name,
            phone_number: user_data.phoneNumber,
            address: "",
            is_member: true,
            is_permanent_member: true,
            membership_status: false,
            created_on: Date.now(),
            isAdmin: false,
            plot: user_data.plotNumber
        };
        const users_collection = doc(db, "users", user.phone_number);
        const res = await setDoc(users_collection, user);
    });
};

export const createBlockDirFromUsers = async () => {
    const db = getFirestore(app);
    const result = {};
    list.forEach(doc => {
        doc.is_permanent_member = true;
        if (doc.phoneNumber != "") result[doc.phoneNumber] = doc;
        else result[doc.name] = doc;
    });
    const block_ref = doc(db, "block_dir", "list");
    const res = await setDoc(block_ref, result);
};

export const updateBlockDirOnMemberChange = async user => {
    const phno = user.phone_number;
    const db = getFirestore(app);
    const block_ref = doc(db, "block_dir", "list");
    const res = await getDoc(block_ref);
    const list = res.data();

    if (!user.is_member && Object.keys(list).includes(phno)) {
        delete list[phno];
    } else {
        list[phno] = {
            name: user.name,
            plotNumber: user.plot,
            phoneNumber: user.phone_number,
            address: user.address,
            is_permanent_member: user.is_permanent_member
        };
    }
    await setDoc(block_ref, list);
};

export const deleteFromBlockDir = async id => {
    const db = getFirestore(app);
    const block_ref = doc(db, "block_dir", "list");
    const res = await getDoc(block_ref);
    const list = res.data();
    delete list[id];
    await setDoc(block_ref, list);
};
