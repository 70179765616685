import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as yup from "yup";
import { Label, Checkbox, FloatingLabel, Button, Card } from "flowbite-react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { updateBlockDirOnMemberChange } from "../helpers/blockDir";
import User from "../helpers/User";

const phone_regex = /^[\+][9][1][1-9]{1}[0-9]{9}$/;

function AddUserPage() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const prefilled_phone_number = location?.state?.prefilled_phone_number;
    const return_after_create = location?.state?.return_after_create;

    const schema = yup.object().shape({
        name: yup.string().required("Name is required"),
        address: yup.string().when("is_member", {
            is: false,
            then: s => s.required("Address is required for non-members"),
            otherwise: s => s.notRequired()
        }),
        plot: yup.string().when("is_member", {
            is: true,
            then: s => s.required("Plot number is required for members"),
            otherwise: s => s.notRequired()
        }),
        phone_number: yup.string().required("Phone number is required").matches(phone_regex, "Phone number is not valid"),
        is_member: yup.boolean(),
        is_permanent_member: yup.boolean()
    });

    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Add New User | fdblock.org</title>
            </Helmet>
            <div className="flex justify-center">
                <Card className="w-4/4 md:w-2/4 mt-5 p-2">
                    <div>
                        <h1 className="text-3xl text-center tracking-wide">Create New User</h1>
                    </div>
                    <Formik
                        initialValues={{
                            name: "",
                            address: "",
                            plot: "",
                            phone_number: prefilled_phone_number ? prefilled_phone_number : "",
                            is_member: false,
                            is_permanent_member: false
                        }}
                        validationSchema={schema}
                        onSubmit={async (values, { resetForm }) => {
                            const user = {
                                name: values.name,
                                address: values.address,
                                plot: values.plot,
                                phone_number: values.phone_number,
                                is_member: values.is_member,
                                is_permanent_member: values.is_permanent_member
                            };

                            const res = await User.CreateNewUser(user);

                            if (res) {
                                toast.success(`New user ${values.name} created`);
                                resetForm({ values: "" });
                                if (return_after_create) navigate(-1);
                            } else {
                                toast.error(`A user with the phone number ${values.phone_number} already exists`);
                            }
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <FloatingLabel
                                    className="mt-3"
                                    variant="filled"
                                    label="Phone number"
                                    name="phone_number"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone_number}
                                    helperText={
                                        <div className="flex flex-col">
                                            <span className="text-md">Format: +91XXXXXXXXXX</span>
                                            {touched.phone_number && errors.phone_number ? <span className="text-md text-red-400">{errors.phone_number}</span> : null}
                                        </div>
                                    }
                                />

                                <FloatingLabel
                                    className="mt-3"
                                    variant="filled"
                                    label="Name"
                                    name="name"
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    helperText={<div className="flex flex-col">{touched.name && errors.name ? <span className="text-md text-red-400">{errors.name}</span> : null}</div>}
                                />

                                <div className={values.is_member ? "block" : "hidden"}>
                                    <FloatingLabel
                                        className="mt-3"
                                        variant="filled"
                                        label="Plot number"
                                        name="plot"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.plot}
                                        helperText={<div className="flex flex-col">{touched.plot && errors.plot ? <span className="text-md text-red-400">{errors.plot}</span> : null}</div>}
                                    />
                                </div>

                                <div className={values.is_member ? "hidden" : "block"}>
                                    <FloatingLabel
                                        className="mt-3"
                                        variant="filled"
                                        label="Address"
                                        name="address"
                                        type="text"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                        helperText={<div className="flex flex-col">{touched.address && errors.address ? <span className="text-md text-red-400">{errors.address}</span> : null}</div>}
                                    />
                                </div>

                                <div className="mt-2">
                                    <Checkbox
                                        className="mr-2"
                                        name="is_member"
                                        type="checkbox"
                                        onChange={e => {
                                            e.preventDefault();
                                            if (!e.target.value) {
                                                setFieldValue("is_permanent_member", false);
                                            }

                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_member}
                                        checked={values.is_member}
                                    />
                                    <Label>Member</Label>
                                </div>
                                <div className="mb-4">
                                    <Checkbox
                                        className="mr-2"
                                        name="is_permanent_member"
                                        type="checkbox"
                                        onChange={e => {
                                            e.preventDefault();
                                            if (e.target.value) {
                                                setFieldValue("is_member", true);
                                            }

                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.is_permanent_member}
                                        checked={values.is_permanent_member}
                                    />
                                    <Label>Lifetime member</Label>
                                </div>

                                <div className="flex gap-0.5 mt-2">
                                    <Button type="submit" disabled={isSubmitting}>
                                        <span className="tracking-wide lowercase">Submit</span>
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Card>
            </div>
        </>
    );
}

export default AddUserPage;
