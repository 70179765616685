import React, { useEffect, useState } from "react";
import LoadingAnimation from "../components/LoadingAnimation";
import { Helmet } from "react-helmet-async";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import app from "../firebase.config";
import { Card, Badge } from "flowbite-react";

const columns = [
    {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: false,
        renderCell: value => value.value,
        valueGetter: params => params.row.name
    },
    {
        field: "phoneNumber",
        headerName: "Phone Number",
        width: 200,
        editable: false
    },
    {
        field: "plotNumber",
        headerName: "Plot",
        width: 200,
        editable: false
    },
    {
        field: "is_permanent_member",
        headerName: "Lifetime Membership",
        width: 200,
        editable: false,
        renderCell: value => {
            return <Badge color={value.value ? "success" : "failure"}>{value.value ? "Active" : "Inactive"}</Badge>;
        }
    }
];

function BlockDirPage() {
    const [usres, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getAllUsers = async () => {
            const db = getFirestore(app);
            let user_data_set = [];
            const block_ref = doc(db, "block_dir", "list");
            const res = await getDoc(block_ref);
            const list = res.data();

            for (const key in list) {
                const user_obj = list[key];
                user_data_set.push({ ...user_obj, id: key });
            }

            setUsers(user_data_set);
            setIsLoading(false);
        };
        setIsLoading(true);
        getAllUsers();
    }, []);

    if (isLoading) {
        return <LoadingAnimation />;
    }

    return (
        <>
            <Helmet>
                <title>Block Directory | fdblock.org</title>
            </Helmet>
            <Card className="mt-5 pt-2 text-center">
                <div className="text-3xl text-center tracking-wide">FD Block Members' Directory</div>
                <DataGrid
                    rows={usres}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 }
                        }
                    }}
                    pageSizeOptions={[10, 15, 20]}
                    disableRowSelectionOnClick
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true
                        }
                    }}
                />
            </Card>
        </>
    );
}

export default BlockDirPage;
