//////////////////////////////////////////////////
//
// Total cost calculation scheme
// If (whole_day_booking_required[event]) {
//     For (each day) { // choice of floor/floor_constraint may vary from day to day
//         If (floor_constraints[event] {
//             cost += floor_cost[event][floor_constraint][member/others]
//         } Else {
//             cost += (floor_cost[event][member/others] * num_of_floors)
//         }
//     }
// } Else {
//     For (each day) { // choice of floor/floor_constraint may vary from day to day
//         For (each floor) { // choice of slots may vary from floor to floor (and consequently, day to day)
//             If (floor_constraints[event] {
//                 cost += (floor_cost[event][floor_constraint][member/others] * num_of_slots)
//             } Else {
//                 cost += (floor_cost[event][member/others] * num_of_slots)
//             }
//         }
//     }
// }
//
// cost += security_deposit[event][member/others]

//////////////////////////////////////////////////

// Front end
import React, { useState, Suspense, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";
import clone from "just-clone";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { Card, Select, Table, Accordion, Checkbox, Button, Textarea, TextInput, Tooltip, Badge } from "flowbite-react";
import { getFirestore, getDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { FaCircleQuestion } from "react-icons/fa6";
// Firebase

// Internal UI components
import DatePicker from "./DatePicker";

// Internal data utils
import Booking from "../../helpers/Booking";
import { DateDiff, GetSetOfDates, FormatTimeSlot, FindChosenFloorConstraint, GetUserNameFromBookingRefStr, GetTimeZone } from "../../helpers/utils";
import PriceSummary from "./PriceSummary";
import { StdContext } from "../../context/StdContext";
import AsyncSelect from ".././AsyncSelect";
import app from "../../firebase.config";

const rates = require("../../helpers/community_hall_rates.json");
const globals = require("../../helpers/globals.json");
const Constants = globals.Constants;
const Collections = globals.Collections;
const DocNames = globals.DocNames;

const EditBooking = ({ startDate = new Date(), endDate = new Date(), bookingObject = null, editMode = false, uid = null }) => {
    if (startDate) {
        // prune the starting date
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
    }

    if (endDate) {
        // prune the ending date
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
        endDate.setMilliseconds(0);
    }

    const navigate = useNavigate();
    const { isFetching, user_data } = useContext(StdContext);
    const this_user_data = user_data;

    // Utility functions
    const CreateOptions = obj => {
        const options = [];
        for (const [k, v] of Object.entries(obj)) {
            options.push(
                <option key={k} value={k}>
                    {v}
                </option>
            );
        }

        return options;
    };

    const [start_date, SetStartDate] = useState(startDate);
    const [end_date, SetEndDate] = useState(endDate);
    const event_types_element = CreateOptions(rates.events);
    const [event, SetEvent] = useState(bookingObject === null ? Object.keys(rates.events)[0] : bookingObject.event_type);

    // Get a set of epoch timestamps denoting the dates which are to be shown as TEMPORARILY unblocked (if in edit mode)
    const unblock_dates = editMode === true ? GetSetOfDates(bookingObject.start_date, bookingObject.end_date) : null;

    const [show_modal, SetShowModal] = useState(false);
    const [request_handle_in_process, SetRequestHandleInProcess] = useState(false);
    const [refundable_deposit_cost, SetRefundableSecurityDespositCost] = useState();

    // Reference field (for non-members)
    const [reference_member, SetReferenceMember] = useState(bookingObject === null ? "" : bookingObject.reference);
    const [refList, setRefList] = useState([]);
    const [blocked_slots, SetBlockedSlots] = useState();
    useEffect(() => {
        const getList = async () => {
            const data = [];
            const db = getFirestore(app);
            const block_ref = doc(db, "block_dir", "list");
            const res = await getDoc(block_ref);
            console.warn("Querying data");
            const list = res.data();

            for (const key in list) {
                const user_obj = list[key];
                data.push({
                    ...user_obj,
                    key: `${user_obj.name} / ${key}`,
                    value: `${user_obj.name} / ${key}`
                });
            }

            setRefList(data);
        };

        const GetBlockedSlots = async () => {
            const db = getFirestore(app);
            const bd = doc(db, Collections.SYSTEM, DocNames.BLOCKED_DATES);
            const snap = await getDoc(bd);
            console.warn("Querying data");
            if (snap.exists()) {
                const data = snap.data();
                SetBlockedSlots(data);
            }
        };

        getList(); // TODO: Point of optimization: no need to create this list if member
        GetBlockedSlots();
    }, []);

    // const InitObjectEntryWithValue = (...args) => {
    // let obj = clone(args[0]);
    // const value = args[1];
    // for (let i = 2; i < args.length - 1; i++) {
    // let t = { placeholder: "" };
    // obj[args[i]] = t;
    // obj = t;
    // console.info(args[0]);
    // }

    // obj[args[args.length - 1]] = value;
    // return args[0];
    // };

    const InitSlotsObjWithValue = (obj, v, a, b, c, d) => {
        if (!(a in obj)) obj[a] = {};
        if (!(b in obj[a])) obj[a][b] = {};
        if (!(c in obj[a][b])) obj[a][b][c] = {};
        if (!(d in obj[a][b][c])) obj[a][b][c][d] = v;

        return obj;
    };

    const date_diff = DateDiff(start_date, end_date);
    const [lock_time_slots, SetLockTimeSlots] = useState(Array(date_diff).fill(false));
    const [blocked_slots_modified, SetBlockedSlotsModified] = useState({});
    useEffect(() => {
        if (!blocked_slots) return;

        let slots = clone(blocked_slots_modified); // Do not modify the state-object directly

        // Before we populate the booking-slots matrix, first, clear out the dates which are not currently selected
        const date_range_set = new Set();
        let date_runner = new Date(start_date);
        for (let d = 0; d < date_diff; d++, date_runner.setDate(date_runner.getDate() + 1)) {
            const current_date = date_runner.valueOf();
            date_range_set.add(current_date);
        }

        for (const dates of Object.values(slots)) {
            for (const date of Object.keys(dates)) {
                if (!date_range_set.has(date)) {
                    delete dates[date];
                }
            }
        }

        // Now, proceed to create the matrix
        const whole_day_booking_required = rates.whole_day_booking_required[event];

        // Find the following free-elements and create a matrix:
        // 1. dates
        // 2. time-slots
        // 3. slots
        // 4. floors

        // List of all floors
        const all_floors = Object.keys(rates.floors);

        // List of all time-slots supported
        const all_time_slots = [];
        for (let h = Constants.BOOKING_START_HOUR; h <= Constants.BOOKING_END_HOUR; h++) all_time_slots.push(h);

        if (whole_day_booking_required) {
            // In this case, we only require the floors which are free during each date in the date-range
            let date_runner = new Date(start_date);
            // Iterate through each date in the range chosen by the user
            for (let d = 0; d < date_diff; d++, date_runner.setDate(date_runner.getDate() + 1)) {
                const current_date = date_runner.valueOf();
                const whole_day_blocked_floors = new Set();
                if (current_date in blocked_slots) {
                    // The current date is blocked in part OR, in whole ; we have to find out which
                    // This event requires a whole-day booking: SOME FLOORS of ALL THE TIME-SLOTS of the CURRENT DATE will get booked

                    // Step 1: Find the floors which are already booked (during some time-slot on the current date)
                    for (const blocked_floors of Object.values(blocked_slots[current_date])) {
                        for (const f of blocked_floors) {
                            whole_day_blocked_floors.add(f);
                        }
                    }
                }

                // Step 2: Find the FREE floors which are avaialble throughout the day
                const free_floors = all_floors.filter(f => !whole_day_blocked_floors.has(f));
                for (const floor of free_floors) {
                    for (const hour of all_time_slots) {
                        // Step 3: 'floor' is free in the full range of time-slots in a day: mark them!
                        slots = InitSlotsObjWithValue(slots, false, event, current_date, hour, floor);
                        slots[event][current_date][hour]["marked"] = true;
                    }
                }
            }
        } else {
            let date_runner = new Date(start_date);
            for (let d = 0; d < date_diff; d++, date_runner.setDate(date_runner.getDate() + 1)) {
                const current_date = date_runner.valueOf();

                // First, the easy bit: mark ALL FLOORS of all the COMPLETELY FREE TIME-SLOTS
                let free_time_slots = null;
                if (!(current_date in blocked_slots)) {
                    // If the current date is not at all blocked, ALL TIME-SLOTS are basically free
                    free_time_slots = all_time_slots;
                } else {
                    // Some time-slots are free
                    free_time_slots = all_time_slots.filter(slot => !(slot in blocked_slots[current_date]));
                }

                for (const hour of free_time_slots) {
                    for (const floor of all_floors) {
                        slots = InitSlotsObjWithValue(slots, false, event, current_date, hour, floor);
                        slots[event][current_date][hour]["marked"] = false;
                    }
                }

                // Some time-slots are booked ; the free ones have been marked by this point
                if (free_time_slots.length < all_time_slots.length) {
                    for (const [blocked_time, blocked_floors] of Object.entries(blocked_slots[current_date])) {
                        // Find the free-floors in each time-slot and mark them
                        const free_floors = all_floors.filter(f => !blocked_floors.includes(f));
                        for (const floor of free_floors) {
                            slots = InitSlotsObjWithValue(slots, false, event, current_date, blocked_time, floor);
                            slots[event][current_date][blocked_time]["marked"] = false;
                        }

                        // If there are no free floors, mark the time-slot as disabled
                        if (free_floors.length === 0) {
                            for (const floor of blocked_floors) {
                                slots = InitSlotsObjWithValue(slots, false, event, current_date, blocked_time, floor);
                                slots[event][current_date][blocked_time]["disabled"] = true;
                            }
                        }
                    }
                }
            }
        }

        SetBlockedSlotsModified(slots);
        SetLockTimeSlots(Array(date_diff).fill(false));
    }, [blocked_slots, event, start_date, end_date]);

    // Costs are different for block-residents and non-residents, for which we first have to determine what kind of user we are dealing with
    // Store the user-type (TODO: Check if this is at all necessary)
    const [booking_user_type, SetBookingUserType] = useState();
    const [booking_user_data, SetBookingUserData] = useState();
    const SetUserTypeForBooking = user => {
        if (user && user["is_permanent_member"] === true) {
            SetBookingUserType("member");
        } else {
            SetBookingUserType("others");
        }
    };

    useEffect(() => {
        if (!isFetching && this_user_data != null) {
            SetUserTypeForBooking(this_user_data);
            SetBookingUserData(this_user_data);
        }
    }, [this_user_data, isFetching]);

    useEffect(() => {
        if (booking_user_type && event) {
            SetRefundableSecurityDespositCost(rates.security_deposit[event][booking_user_type]);
        }
    }, [booking_user_type, event]);

    const [detailed_description, SetDetailedDescription] = useState("");
    const CloneAndProcessSlotsObject = slots_booked => {
        const slots_booked_cloned = clone(slots_booked);
        for (const time_slots of Object.values(slots_booked_cloned)) {
            for (const [time_slot, floors] of Object.entries(time_slots)) {
                if ("disabled" in floors) {
                    delete time_slots[time_slot];
                    continue;
                }

                // TODO: Write logic for marking an extra hour in case of "meeting" (for cleaning and all)
                // if (event == "meeting" && !time_slot.marked && ) {
                // for (const [floor, marked] of Object.entries(floors)) {
                // }
                // }
            }
        }

        return slots_booked_cloned;
    };

    const HandleConfirmBooing = async e => {
        e.preventDefault();

        // CanConfirm will return a string with the error message (if the booking cannot be confirmed) and an empty string for a valid booking
        const can_confirm = CanConfirm(blocked_slots_modified[event], event);
        if (can_confirm.length > 0) {
            console.log(can_confirm.length);
            toast.error("Cannot create a booking with incomplete fields");
            return;
        }

        const blocked_slots_modified_copy = CloneAndProcessSlotsObject(blocked_slots_modified[event]);
        SetRequestHandleInProcess(true);
        let newly_created_booking_data = null;
        let message = "Provisional booking has been created";
        try {
            const result = await Booking.CreateDoc({
                user_id: Booking.CreateUserIdStr(booking_user_data.name, booking_user_data.phone_number),
                is_block_member: booking_user_data && booking_user_data["is_permanent_member"] === true,
                start_date: start_date,
                end_date: end_date,
                event_type: event,
                event_description: detailed_description,
                reference: reference_member,
                slots_booked: blocked_slots_modified_copy,
                refundable_deposit: refundable_deposit_cost,
                user_email: booking_user_email,
                reference_user_email: reference_member_email,
                time_zone: GetTimeZone()
            });

            if (result.status) {
                newly_created_booking_data = result.data;
            } else {
                message = result.message;
            }

            setTimeout(() => {
                SetRequestHandleInProcess(false);
                SetShowModal(false);

                if (newly_created_booking_data && newly_created_booking_data.id) {
                    navigate(`/dashboard/bookings/view-booking?id=${newly_created_booking_data.id}`);
                    toast.success(message);
                } else {
                    message = `Could not create booking (${message})`;
                    toast.error(message);
                }
            }, 1000);
        } catch (err) {}
    };

    ////////////////////////////////////////////////////////////////////////////////

    const lock_floors = Array(date_diff).fill(false);
    const CreateFloorCheckbox = (date, floor_num, floor_name, time_slot) => {
        const date_idx = (date - start_date) / 86400000;
        return (
            <div key={Math.random()}>
                <Checkbox
                    onChange={e => {
                        let slots = clone(blocked_slots_modified);
                        const s = slots[event][date];
                        for (let ii = 0; ii < rates.min_time_slot[event] && ii + parseInt(time_slot) <= Constants.BOOKING_END_HOUR; ii++) {
                            const key = String(ii + parseInt(time_slot));
                            s[key][floor_num] = e.target.checked; // Mark as true or false
                        }

                        SetBlockedSlotsModified(slots);
                    }}
                    checked={blocked_slots_modified[event][date][time_slot][floor_num] === true}
                    disabled={lock_floors[date_idx]}
                />
                <span className={"pl-4 font-mono".concat(lock_floors[date_idx] ? " text-slate-400" : "")}>{floor_name}</span>
            </div>
        );
    };

    const ShowFreeSlots = date => {
        if (!date || !event || !(event in blocked_slots_modified) || !(date in blocked_slots_modified[event])) {
            return <div className="text-center lowercase text-black tracking-wide">no slots available</div>;
        }

        // For whole-day booking events, we will not show any time-slots, only the floor-choices
        // If a floor is marked by the user, that floor in all the time-slots will be marked as true
        if (rates.whole_day_booking_required[event]) {
            const floors = [];
            for (const [floor_num, floor_name] of Object.entries(rates.floors)) {
                if (!(floor_num in blocked_slots_modified[event][date][Constants.BOOKING_START_HOUR])) continue;
                floors.push(CreateFloorCheckbox(date, floor_num, floor_name, Constants.BOOKING_START_HOUR));
            }

            // The above code is enough to show the floor-choices ; now, we have to produce an error-message
            // for events which have certain sets of floor-constraints ; the following code is to generate that message
            let show_floor_constraint_error = false;
            const floor_constraint_error = [];
            if (event in rates.floor_constraints) {
                show_floor_constraint_error = isNaN(FindChosenFloorConstraint(event, blocked_slots_modified[event][date][Constants.BOOKING_START_HOUR]));
                if (show_floor_constraint_error) {
                    for (const constraint of Object.values(rates.floor_constraints[event])) {
                        let msg = "";
                        for (let i = 0; i < constraint.length; i++) {
                            const f = constraint[i];
                            msg += rates.floors[f];
                            if (i < constraint.length - 1) msg += " + ";
                        }

                        floor_constraint_error.push(
                            <div className="ml-3" key={Math.random()}>
                                &#8226; {msg}
                            </div>
                        );
                    }
                }
            }

            return (
                <div>
                    {floors}
                    {show_floor_constraint_error ? (
                        <div className="font-mono pt-3 text-red-700">
                            One of the following floor combinations can be chosen
                            {floor_constraint_error}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        }

        // First, create the checkboxes for the time-slots and store them in an array of pairs (checkbox-element, checkbox-label)
        // This array will later on be used to create the rows of the slots-table
        const checkboxes = [];
        const time_slots = Object.keys(blocked_slots_modified[event][date]);
        const date_idx = (date - start_date) / 86400000;
        for (const t of time_slots) {
            const floors = blocked_slots_modified[event][date][t];
            checkboxes.push([
                <Checkbox
                    onChange={e => {
                        let slots = clone(blocked_slots_modified);
                        // UNMARK-ing procedure: Uncheck the time-slots with same group-number
                        if (!e.target.checked) {
                            slots[event][date][t]["marked"] = false;
                            SetBlockedSlotsModified(slots);
                            return;
                        }

                        slots[event][date][t]["marked"] = true;
                        SetBlockedSlotsModified(slots);
                    }}
                    defaultChecked={blocked_slots_modified[event][date][t]["marked"] === true}
                    disabled={lock_time_slots[date_idx] || "disabled" in floors}
                />,
                <span
                    className={"pl-4 font-mono".concat(lock_time_slots[date_idx] ? " text-slate-400" : "").concat("disabled" in floors ? " line-through" : "")}
                >
                    {FormatTimeSlot(t, "00")}
                    {" - "}
                    {FormatTimeSlot(t, "59")}
                </span>
            ]);
        }

        const table_rows = [];
        const time_slots_values = Object.values(blocked_slots_modified[event][date]);
        for (let i = 0; i < time_slots_values.length; ) {
            let t = time_slots_values[i];
            if (lock_time_slots[date_idx] && t.marked === true) {
                // Display the time-slots (non-editable) and the floor-choices
                // Since a sanity-check for grouped time-slots has already been done (otherwise we would not be at this point in the code),
                // we can safely create elements for the next 'min_time_slot[event]' number of time-slots (and show them grouped toegether),
                // and, subsequently, increment the loop-counter by 'min_time_slot[event]'.
                const time_slot_set = [];
                for (let ii = 0; ii < rates.min_time_slot[event]; ii++) {
                    t = time_slots_values[i + ii];
                    time_slot_set.push(
                        <div key={Math.random()}>
                            {checkboxes[i + ii][0]}
                            {checkboxes[i + ii][1]}
                        </div>
                    );
                }

                const floor_set = [];
                const time_slot = Object.keys(blocked_slots_modified[event][date])[i];
                for (const [floor_num, floor_name] of Object.entries(rates.floors)) {
                    let skip_floor = false;
                    for (let ii = 0; ii < rates.min_time_slot[event] && i + ii < time_slots_values.length; ii++) {
                        t = time_slots_values[i + ii];
                        if (!(floor_num in t)) {
                            skip_floor = true;
                            break;
                        }
                    }

                    if (!skip_floor) {
                        floor_set.push(CreateFloorCheckbox(date, floor_num, floor_name, time_slot));
                    }
                }

                table_rows.push(
                    <Table.Row className="mx-1 bg-white dark:border-gray-700 dark:bg-gray-800" key={Math.random()}>
                        <Table.Cell className="text-center">{time_slot_set}</Table.Cell>
                        <Table.Cell className="">{floor_set}</Table.Cell>
                    </Table.Row>
                );

                i += rates.min_time_slot[event];
            } else {
                // Display the time-slots only (no floor choices)
                table_rows.push(
                    <Table.Row className="justify-center bg-white dark:border-gray-700 dark:bg-gray-800" key={Math.random()}>
                        <Table.Cell className="text-center mx-1 py-0">
                            {checkboxes[i][0]}
                            {checkboxes[i][1]}
                        </Table.Cell>
                    </Table.Row>
                );

                i++;
            }
        }

        const HandleConfirmTimeSlots = () => {
            const error_message = `Please select consecutive hour-groups of ${rates.min_time_slot[event]}`;
            for (let i = 0; i < time_slots_values.length; ) {
                let t = time_slots_values[i];
                if (t.marked === true) {
                    for (let ii = 1; ii < rates.min_time_slot[event]; ii++) {
                        if (i + ii >= time_slots_values.length) {
                            toast.error(error_message);
                            return;
                        }

                        t = time_slots_values[i + ii];
                        if (t.marked === false) {
                            toast.error(error_message);
                            return;
                        }
                    }

                    i += rates.min_time_slot[event];
                    continue;
                }

                i++;
            }

            const t = [...lock_time_slots];
            t[date_idx] = true;
            SetLockTimeSlots(t);
        };

        const HandleEditTimeSlots = () => {
            const t = [...lock_time_slots];
            t[date_idx] = false;
            SetLockTimeSlots(t);
        };

        return (
            <div className="flex flex-col flex-1">
                <Table>
                    <Table.Body className="divide-y">{table_rows}</Table.Body>
                </Table>
                <div className="pt-3">
                    {!lock_time_slots[date_idx] ? (
                        <Button size="sm" pill color="success" className="w-56" onClick={HandleConfirmTimeSlots}>
                            confirm time-slots
                        </Button>
                    ) : (
                        <Button size="sm" pill color="warning" className="w-56" onClick={HandleEditTimeSlots}>
                            edit time-slots
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    // Calculate the number of days to be booked
    const date_cards = [];
    let date = new Date(start_date);
    for (let d = 0; d < date_diff; d++) {
        date_cards.push(
            <Accordion.Panel className="m-0 p-0" key={Math.random()}>
                <Accordion.Title className="lowercase text-black tracking-wide">
                    Day {d + 1} ({date.toLocaleString("en-US", { weekday: "long", month: "long", day: "numeric", year: "numeric" })})
                </Accordion.Title>
                <Accordion.Content className="p-5">{ShowFreeSlots(date.valueOf())}</Accordion.Content>
            </Accordion.Panel>
        );

        date.setDate(date.getDate() + 1);
    }

    const [phone_number_to_book_for, SetPhoneNumberToBookFor] = useState("");
    const [show_create_new_user_modal, SetShowCreateNewUserModal] = useState(false);
    const HandleSearchPhoneNumberToBookFor = async e => {
        const phone_regex = /^[\+][9][1][1-9]{1}[0-9]{9}$/;
        if (!phone_regex.test(phone_number_to_book_for)) {
            toast.error("Enter a valid phone-number");
            return;
        }

        const db = getFirestore(app);
        const user_ref = doc(db, Collections.USERS, phone_number_to_book_for);
        const snap = await getDoc(user_ref);
        console.warn("Querying data");
        if (snap.exists()) {
            const d = snap.data();
            SetUserTypeForBooking(d);
            SetBookingUserData(d);
        } else {
            SetShowCreateNewUserModal(true);
        }
    };

    const [booking_user_email, SetBookingUserEmail] = useState("");
    const [reference_member_email, SetReferenceMemberEmail] = useState("");
    const email_regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const emails_verified = email_regex.test(booking_user_email) && (this_user_data?.is_member || email_regex.test(reference_member_email));

    const CanConfirm = (slots_booked, event) => {
        if (Object.keys(slots_booked).length > Constants.BOOKING_LIMIT_NUM_OF_DAYS) {
            return `A single booking cannot be made for more than ${Constants.BOOKING_LIMIT_NUM_OF_DAYS} days`;
        }

        let date_set = new Set();
        const whole_day_booking_required = rates.whole_day_booking_required[event];
        for (const [date, time_slots] of Object.entries(slots_booked)) {
            let actual_time_slots = time_slots;
            if (whole_day_booking_required) {
                actual_time_slots = { _: time_slots[Constants.BOOKING_START_HOUR] };
            }

            let time_slot_idx = 0; // Each time-slot (as per rates.min_time_slot[event]) is to be counted ; NOT every hour in that time-slot
            for (const floors of Object.values(actual_time_slots)) {
                if (!floors.marked) continue;

                if (!whole_day_booking_required) {
                    if (time_slot_idx > 0 && time_slot_idx < rates.min_time_slot[event]) {
                        time_slot_idx++;
                        continue;
                    } else {
                        time_slot_idx = 1;
                    }
                }

                if (event in rates.floor_constraints) {
                    const chosen_floor_constraint = FindChosenFloorConstraint(event, floors);
                    if (isNaN(chosen_floor_constraint)) continue;

                    date_set.add(date);
                } else {
                    for (const [floor, marked] of Object.entries(floors)) {
                        if (!marked) continue;

                        if (floor in rates.floors) {
                            date_set.add(date);
                        }
                    }
                }
            }
        }

        if (date_set.size !== Object.keys(slots_booked).length) return "Please select floor(s) from each day";
        if (!this_user_data.isAdmin && booking_user_type === "others" && reference_member.length === 0) return "Please add a reference-member for your booking";

        return "";
    };

    const [rules_and_regulations_checked, SetRulesAndRegulationsChecked] = useState(false);
    // Fetch data from the cost table
    if (isFetching) {
        // When the user's logged in state is yet to be determined, show a loading animation
        return <LoadingAnimation />;
    }

    return (
        <div className="my-5">
            <Card>
                {editMode === false ? (
                    <div>
                        <div className="text-3xl text-center mt-5 mb-10">Complete your booking</div>
                        <hr />
                    </div>
                ) : (
                    <div>
                        <div className="text-xl mb-5">Edit booking request</div>
                        <hr />
                    </div>
                )}
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-1 p-1 md:p-3">
                        <div className="mb-5">
                            {this_user_data?.isAdmin ? (
                                <div className="mb-3">
                                    <div className="text-right">
                                        <span className="text-xl text-black tracking-wide text-right">create this booking for "{booking_user_data?.name}"</span>
                                    </div>
                                    <div className="mt-3 flex flex-row space-between items-center gap-x-5 justify-end">
                                        <TextInput
                                            placeholder="+91XXXXXXXXXX"
                                            value={phone_number_to_book_for}
                                            onChange={e => {
                                                SetPhoneNumberToBookFor(e.target.value);
                                            }}
                                        />

                                        <Button size="sm" color="success" className="w-56" onClick={HandleSearchPhoneNumberToBookFor}>
                                            search for user
                                        </Button>
                                    </div>
                                    <hr className="mt-5" />
                                </div>
                            ) : null}
                            {/***** Event type dropdown menu *****/}
                            <div className="">
                                <div className="text-right">
                                    <span className="text-xl text-black tracking-wide lowercase text-right">Purpose of booking</span>
                                </div>
                                <div className="mt-3">
                                    <Select
                                        value={event}
                                        onChange={e => {
                                            SetEvent(e.target.value);
                                        }}
                                        id="event-type"
                                        required={true}
                                    >
                                        {event_types_element}
                                    </Select>
                                </div>
                            </div>

                            <div className="mt-3">
                                <div className="text-right">
                                    <span className="text-xl text-black tracking-wide lowercase text-right">Detailed description of booking</span>
                                </div>
                                <div className="mt-3">
                                    <Textarea
                                        placeholder="Birthday, workshop, etc."
                                        required
                                        rows={2}
                                        value={detailed_description}
                                        onChange={e => {
                                            SetDetailedDescription(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>

                            {/***** End of event type dropdown menu *****/}
                            {!this_user_data.is_member && (
                                <div className="">
                                    <div className="mb-2 block mt-7 text-right flex flex-col justify-end items-end">
                                        <span className="text-xl text-black tracking-wide text-right flex flex-row justify-end items-center">
                                            <span className="text-sm mr-1">
                                                <Tooltip
                                                    content="An FD block member must be provided as reference. This member will be notified about your booking."
                                                    placement="top"
                                                >
                                                    <FaCircleQuestion />
                                                </Tooltip>
                                            </span>
                                            reference of <span className="text-red-500">*</span>
                                        </span>
                                        <span className="text-gray-600 italic text-sm mr-1">
                                            Your booking will be approved after this member's approval has been received
                                        </span>
                                    </div>
                                    <AsyncSelect
                                        loadOptions={async value => {
                                            try {
                                                let data = [];

                                                if (refList.length) {
                                                    data = refList.filter((item, index) => {
                                                        return item.value.toLowerCase().includes(value.toLowerCase());
                                                    });
                                                }
                                                return data;
                                            } catch (error) {
                                                return [];
                                            }
                                        }}
                                        value={reference_member}
                                        onChange={value => SetReferenceMember(value)}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="flex justify-center items-center md:scale-125 m-20">
                            <Suspense fallback={<Spinner color="success" aria-label="Success spinner example" />}>
                                <DatePicker
                                    StartDate={start_date}
                                    SetStartDate={SetStartDate}
                                    EndDate={end_date}
                                    SetEndDate={SetEndDate}
                                    unblockedSetOfDates={unblock_dates}
                                />
                            </Suspense>
                        </div>

                        <div className="mb-5">
                            <div>
                                <div className="text-xl pb-3 text-right tracking-wide lowercase">
                                    choose {!rates.whole_day_booking_required[event] ? "time-slots and " : ""}floors for each day
                                </div>
                                <Accordion collapseAll>{date_cards}</Accordion>
                            </div>
                            <div className="mt-5">
                                <div className="text-xl pb-3 text-right tracking-wide lowercase">Price Summary</div>
                                <PriceSummary
                                    user_type={booking_user_type}
                                    event={event}
                                    slots_booked={blocked_slots_modified[event]}
                                    refundable_deposit_cost={refundable_deposit_cost}
                                />
                            </div>

                            <div className="mt-5 float-right">
                                <Button
                                    size="sm"
                                    pill
                                    className="w-56"
                                    onClick={() => {
                                        const status = CanConfirm(blocked_slots_modified[event], event);
                                        if (status === "") SetShowModal(true);
                                        else toast.error(status);
                                    }}
                                >
                                    confirm booking
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/***** End of calendar and billing details *****/}

                    {/***** Cancellation and security deposite details *****/}

                    <hr />

                    <div className="grid grid-cols-1 sm:grid-cols-2 px-4 my-7">
                        <div className="text-slate-500 py-2">
                            <h5 className="text-lg font-semibold">Cancellation Policy</h5>
                            <ul className="list-none mt-1">
                                <li>Prior to three days before event: 75% of the cost</li>
                                <li>One to three days before event: 50% of the cost</li>
                                <li>On the day of the event: ₹0 will be refunded</li>
                                <li>Nothing will be deducted from the security deposit</li>
                            </ul>
                        </div>
                        <div className="text-slate-500 py-2">
                            <h5 className="text-lg font-semibold">Security Deposit</h5>
                            <ul className="list-none mt-1">
                                <li>Electricity consumption will be deducted</li>
                                <li>Lift usage cost will be deducted (₹500 per every 12 hours)</li>
                                <li>Note: A lift operator will be provided</li>
                            </ul>
                        </div>
                    </div>
                    {/***** End of cancellation and security deposite details *****/}
                </div>
                <Modal
                    show={show_modal}
                    onHide={() => {
                        SetShowModal(false);
                    }}
                    backdrop="static"
                >
                    <Modal.Header closeButton={true}>
                        <Modal.Title>Confirm and send booking details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {request_handle_in_process === false ? (
                            <div className="text-center">
                                <div className="mb-5 flex flex-col gap-3">
                                    <div className="text-right">
                                        <div className="text-md pl-1 mb-1 flex flex-col justify-end items-end">
                                            <span className="text-lg">your email</span>
                                            <span className="text-yellow-600 italic text-sm mr-1">
                                                The booking summary will be sent to this email for your records
                                            </span>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <TextInput
                                                className="flex-auto"
                                                type="email"
                                                onChange={e => {
                                                    SetBookingUserEmail(e.target.value);
                                                }}
                                                value={booking_user_email}
                                                placeholder="someone@email.com"
                                            />
                                            {!booking_user_email || email_regex.test(booking_user_email) ? null : (
                                                <span className="text-red-500 text-sm">invalid email address</span>
                                            )}
                                        </div>
                                    </div>
                                    {!this_user_data.is_member ? (
                                        <div className="text-right">
                                            <div className="text-md pl-1 mb-1 flex flex-col justify-end items-end">
                                                <span className="text-lg">{GetUserNameFromBookingRefStr(reference_member)}'s email</span>
                                                <span className="text-yellow-600 italic text-sm mr-1">
                                                    The reference member ({GetUserNameFromBookingRefStr(reference_member)}) will also receive the
                                                    <br />
                                                    booking summary (for confirmation) via the email you provide below
                                                </span>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <TextInput
                                                    className="flex-auto"
                                                    type="email"
                                                    onChange={e => {
                                                        SetReferenceMemberEmail(e.target.value);
                                                    }}
                                                    value={reference_member_email}
                                                    placeholder="someone@email.com"
                                                />
                                                {!reference_member_email || email_regex.test(reference_member_email) ? (
                                                    reference_member_email && booking_user_email === reference_member_email ? (
                                                        <span className="text-red-500 text-sm">
                                                            {GetUserNameFromBookingRefStr(reference_member)}'s email must be different from your email
                                                        </span>
                                                    ) : null
                                                ) : (
                                                    <span className="text-red-500 text-sm">invalid email address</span>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <Checkbox
                                                onChange={e => {
                                                    SetRulesAndRegulationsChecked(e.target.checked);
                                                }}
                                                checked={rules_and_regulations_checked}
                                            />
                                            <div className="flex items-center flex-row text-left">
                                                <span className="mr-1">I agree to the </span>
                                                <span className="text-cyan-700 hover:text-cyan-500">
                                                    <Link to="/community-hall-rules-and-regulations" target="_blank">
                                                        rules and regulations
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center gap-4">
                                    <Button onClick={HandleConfirmBooing} disabled={!emails_verified || !rules_and_regulations_checked}>
                                        Confirm booking
                                    </Button>
                                    <Button
                                        color="gray"
                                        onClick={() => {
                                            SetShowModal(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col text-center">
                                <span className="mb-2 text-lg">{editMode === true ? "Updating" : "Creating"} booking request</span>
                                <div>
                                    <Spinner animation="grow" variant="primary" />
                                    <Spinner animation="grow" variant="secondary" />
                                    <Spinner animation="grow" variant="success" />
                                    <Spinner animation="grow" variant="danger" />
                                    <Spinner animation="grow" variant="warning" />
                                    <Spinner animation="grow" variant="info" />
                                </div>
                            </div>
                        )}
                        <hr className="my-4" />
                        <div className="text-slate-500">
                            <h5 className="text-base font-semibold mt-2 text-left">Note</h5>
                            <ul className="list-none text-sm mt-2 text-left">
                                <li>This request will be notified to the block committee.</li>
                                <li>After confirmation of payment, they will approve this booking.</li>
                                <li>
                                    <b>NOTE:</b> They have the discretion to reject the booking request.
                                </li>
                            </ul>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={show_create_new_user_modal}
                    onHide={() => {
                        SetShowCreateNewUserModal(false);
                    }}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>No user found</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-col">
                            <span>The phone number "{phone_number_to_book_for}" is not linked to any user.</span>
                            <span>You can create a new user and come back to this page.</span>
                            <Button
                                className="mt-3"
                                color="success"
                                pill
                                onClick={e => {
                                    SetShowCreateNewUserModal(false);
                                    navigate("/admin/users/create-new", {
                                        state: {
                                            prefilled_phone_number: phone_number_to_book_for,
                                            return_after_create: true
                                        }
                                    });
                                }}
                            >
                                create new user
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Card>
        </div>
    );
};

export default EditBooking;
