import { getFirestore, getDocs, collection} from "firebase/firestore";
import React, { useEffect, useState} from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaSearchPlus } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, Button, Badge, Tabs } from "flowbite-react";

import app from "../firebase.config.js";
import LoadingAnimation from "../components/LoadingAnimation";
import Booking from "../helpers/Booking.js";

const rates = require("../helpers/community_hall_rates.json");
const globals = require("../helpers/globals.json");
const Collections = globals.Collections;
const Constants = globals.Constants;

const columns = [
    {
        field: "booking_id",
        headerName: "Booking number",
        width: 150,
        editable: false,
        valueGetter: params => {
            return params.row.booking_reference_id;
        },
        renderCell: value => {
            const booking_reference_id = value.row[Booking.KEY_BOOKING_REFERENCE_ID];
            const url = `/admin/bookings/view-booking?id=${value.row[Booking.KEY_ID]}`;
            return (
                <Link to={url}>
                    <Button pill color="gray" size="sm">
                        <div className="flex flex-row items-center gap-x-2 justify-center">
                            {booking_reference_id}
                            <FaSearchPlus />
                        </div>
                    </Button>
                </Link>
            );
        }
    },
    {
        field: "start_date",
        headerName: "Start date",
        width: 150,
        editable: false,
        renderCell: value => {
            return value.value.toDateString();
        }
    },
    {
        field: "end_date",
        headerName: "End date",
        width: 150,
        editable: false,
        renderCell: value => {
            return value.value.toDateString();
        }
    },
    {
        field: "event_type",
        headerName: "Type",
        width: 150,
        editable: false,
        renderCell: value => {
            return rates.events[value.value];
        }
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        editable: false,
        renderCell: value => {
            switch (value.value) {
                case "request":
                    return <Badge color="warning">Provisional</Badge>;
                case "confirmed":
                    return <Badge color="success">Confirmed</Badge>;
                case "rejected":
                    return <Badge color="failure">Rejected</Badge>;
                default:
                    break;
            }
        }
    },
    {
        field: "reference",
        headerName: "Reference of",
        width: 150,
        editable: false
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
        editable: false
    },
    {
        field: "phone_number",
        headerName: "Phone Number",
        width: 150,
        editable: false
    }
];

const filterBookings = (bookings, filter_type) => {
    return bookings.filter(booking => {
        switch (filter_type) {
            case "requests":
                if (booking.status === Constants.STATUS_REQUEST) return true;
                break;

            default: break ;
        }

        return false;
    });
};

function AllBookingsPage() {
    const [bookings, setBookings] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getBookings = async () => {
            let booking_data_set = [];

            const db = getFirestore(app);
            const bookings_collection = collection(db, Collections.BOOKINGS);
            const bookings = await getDocs(bookings_collection.withConverter(Booking.FirestoreConverter));
            bookings.forEach(booking_doc => {
                const booking_obj = booking_doc.data();
                booking_data_set.push({
                    ...booking_obj,
                    name: booking_obj.GetUserName(),
                    phone_number: booking_obj.GetUserId()
                });
            });

            setBookings(booking_data_set);
            setIsLoading(false);
        };

        getBookings();
    }, []);

    if (isLoading) {
        return <LoadingAnimation />;
    }

    const data_grid_initial_state = {
        pagination: {
            paginationModel: { page: 0, pageSize: 10 }
        },
        sorting: {
            sortModel: [{ field: "booking_id", sort: "desc" }]
        }
    };

    return (
        <>
            <Helmet>
                <title>All bookings | fdblock.org</title>
            </Helmet>
            <div className="flex justify-center">
                <Card className="mt-5 p-2 w-[100%]">
                    <div>
                        <h1 className="text-3xl text-center tracking-wide">All Bookings</h1>
                    </div>
                    <div className="overflow-x-auto">
                        <Tabs style="underline">
                            <Tabs.Item title="All">
                                <DataGrid
                                    rows={bookings}
                                    columns={columns}
                                    initialState={data_grid_initial_state}
                                    pageSizeOptions={[10, 15, 20]}
                                    disableRowSelectionOnClick
                                    disableDensitySelector
                                    slots={{ toolbar: GridToolbar }}
                                />
                            </Tabs.Item>
                            <Tabs.Item title="Provisional Bookings">
                                <DataGrid
                                    rows={filterBookings(bookings, "requests")}
                                    columns={columns}
                                    initialState={data_grid_initial_state}
                                    pageSizeOptions={[10, 15, 20]}
                                    disableRowSelectionOnClick
                                    disableDensitySelector
                                    slots={{ toolbar: GridToolbar }}
                                />
                            </Tabs.Item>
                        </Tabs>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default AllBookingsPage;
