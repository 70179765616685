import { Helmet } from "react-helmet-async";
import { CgSpinner } from "react-icons/cg";
import { Card, Button } from "flowbite-react";

import OtpInput from "otp-input-react";
import { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { StdContext } from "../context/StdContext";
import LoadingAnimation from "../components/LoadingAnimation";

export default function LoginPage() {
    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const from = params.get("from") || "/";
    const [enableResend, setEnableResend] = useState(true);

    const { isFetching, SignedIn } = useContext(StdContext);

    useEffect(() => {
        let interval;
        if (enableResend && showOTP) {
            interval = setTimeout(() => {
                setEnableResend(false);
            }, 12000);
        }

        return () => {
            if (interval) clearTimeout(interval);
        };
    }, [enableResend, showOTP]);

    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: response => {
                        onSignup();
                    },
                    "expired-callback": () => {}
                },
                auth
            );
        }
    }

    function onSignup(evt) {
        evt.preventDefault();
        setLoading(true);
        onCaptchVerify();

        const appVerifier = window.recaptchaVerifier;

        const formatPh = "+" + ph;

        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setShowOTP(true);
                toast.success("OTP sent");
            })
            .catch(error => {
                toast.error("Unable to send otp");
                console.log(error);
                setLoading(false);
            });
    }

    function onOTPVerify() {
        setLoading(true);
        window.confirmationResult
            .confirm(otp)
            .then(async res => {
                setUser(res.user);
                setLoading(false);
                navigate(from);
            })
            .catch(err => {
                toast.error("OTP verfication failed");
                setLoading(false);
            });
    }

    function resendOTP(e) {
        setEnableResend(true);
        onSignup(e);
    }

    if (isFetching) {
        return <LoadingAnimation />;
    }

    if (SignedIn()) {
        navigate("/");
    }

    return (
        <>
            <Helmet>
                <title>Login | fdblock.org</title>
            </Helmet>
            <section className="flex items-start justify-center h-screen">
                <div>
                    <div id="recaptcha-container"></div>
                    {!user && (
                        <Card className="mt-5">
                            <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
                                {showOTP ? (
                                    <>
                                        <div className="text-4xl text-center pt-10">Login</div>
                                        <label htmlFor="otp" className="font-bold text-xl text-center">
                                            Enter your OTP
                                        </label>
                                        <div className="text-center">Enter the 6-digit verification code that was sent to +{ph}</div>
                                        <OtpInput value={otp} onChange={setOtp} OTPLength={6} otpType="number" disabled={false} autoFocus className="opt-container "></OtpInput>
                                        <Button onClick={onOTPVerify} color="blue">
                                            {loading ? <CgSpinner size={20} className="mt-1 animate-spin" /> : " Verify OTP"}
                                        </Button>
                                        <div className="flex items-center justify-center">
                                            <Button
                                                color="blue"
                                                disabled={enableResend}
                                                onClick={e => {
                                                    resendOTP(e);
                                                }}
                                            >
                                                Resend OTP {enableResend && "after 2 min"}
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <label htmlFor="" className="text-3xl text-center">
                                            Login with your phone-number
                                        </label>
                                        <PhoneInput country={"in"} value={ph} onChange={setPh} />
                                        <Button onClick={onSignup}>
                                            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
                                            <span>Send verification code via SMS</span>
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Card>
                    )}
                </div>
            </section>
        </>
    );
}
