import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "flowbite-react";

import ListBookings from "../components/bookings/ListBookings";

const Bookings = () => {
    return (
        <>
            <Helmet>
                <title>Bookings | fdblock.org</title>
            </Helmet>
            <div className="w-full">
                <Card className="p-2 mt-10">
                    <h3 className="text-2xl text-right">My bookings</h3>
                    <ListBookings /* listAll = false, i.e., user only */ />
                </Card>
            </div>
        </>
    );
};

export default Bookings;
