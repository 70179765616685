import React from "react";
import { Helmet } from "react-helmet-async";
import { Table, Card, Button } from "flowbite-react";
import { useState } from "react";
// import tender_pdf from "../assets/documents/tender_document_cctv_240314.pdf";

const notice_board_header = ["Release date", "Title", "Link"];

const notice_board_contents = [
    // {
    // date: "12th March, 2024",
    // title: "Tender document for IP based video surveillance system (CCTV)",
    // file: "../assets/documents/tender_document_cctv_240314.pdf"
    // }
];

function NoticeBoardPage() {
    return (
        <>
            <Helmet>
                <title>Notice Board | fdblock.org</title>
            </Helmet>
            <div className="mt-4">
                <Card className="text-center">
                    <div className="text-3xl text-center tracking-wide">Notice Board</div>
                    <Table hoverable>
                        <Table.Head className="text-center">
                            {notice_board_header.map(h => (
                                <Table.HeadCell key={Math.random()}>{h}</Table.HeadCell>
                            ))}
                        </Table.Head>
                        <Table.Body>
                            {notice_board_contents.map(c => (
                                <Table.Row className="text-center" key={Math.random()}>
                                    <Table.Cell>{c.date}</Table.Cell>
                                    <Table.Cell>{c.title}</Table.Cell>
                                    <Table.Cell>
                                        <a href={c.file} className="ml-0 flex items-center text-sm font-medium text-cyan-600 hover:underline dark:text-cyan-500 md:ml-1 md:inline-flex">
                                            Download
                                        </a>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Card>
            </div>
        </>
    );
}

export default NoticeBoardPage;
