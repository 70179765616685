import * as React from "react";
import { Helmet } from "react-helmet-async";

export default function ContactUsPage() {
    return (
        <>
            <Helmet>
                <title>Contact Us | fdblock.org</title>
            </Helmet>
            <div className="text-4xl text-center pt-10">Get in touch!</div>
        </>
    );
}
