// Front end
import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import EditBooking from "../components/bookings/EditBooking";

const CompleteBookingPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sd = params.get("sd") || new Date().valueOf();
    const ed = params.get("ed") || new Date().valueOf();
    const uid = params.get("uid");
    const start_date = new Date(parseInt(sd));
    const end_date = new Date(parseInt(ed));

    return (
        <>
            <Helmet>Complete Booking | fdblock.org</Helmet>
            <EditBooking startDate={start_date} endDate={end_date} editMode={false} uid={uid} />
        </>
    );
};

export default CompleteBookingPage;
