// Front end
import React, { useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Spinner, CustomFlowbiteTheme, Navbar, Dropdown } from "flowbite-react";
import { FaRegUserCircle } from "react-icons/fa";

// Internal
import { StdContext } from "../context/StdContext";
//import fd_block_logo from "/public/assets/images/fdblock.png";

// Define the URLs and their page titles below
const routes = new Map();
routes.set("home", ["/", "Home"]);
routes.set("notice-board", ["/notice-board", "Notice Board"]);
routes.set("new-booking", ["/new-booking", "Community Hall Booking"]);

const CustomNavbarTheme: CustomFlowbiteTheme["ul"] = {
    collapse: {
        base: "w-full md:block md:w-auto",
        list: "flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
        hidden: {
            on: "hidden",
            off: ""
        }
    },
    link: {
        base: "block py-2 pr-4 pl-3 md:p-0",
        active: {
            on: "dark:text-white underline decoration-2 decoration-cyan-700 underline-offset-8 text-cyan-700",
            off: "border-b border-gray-100  text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white"
        },
        disabled: {
            on: "text-gray-400 hover:cursor-not-allowed dark:text-gray-600",
            off: ""
        }
    }
};

const Header = () => {
    const location = useLocation();
    const navbar_elements = [];
    routes.forEach((page_title, _) => {
        navbar_elements.push({
            route: page_title[0],
            title: page_title[1]
        });
    });

    const { user_phone_number, SignedIn, SignOut, user_data, isFetching } = useContext(StdContext);
    const signed_in_user_is_admin = SignedIn() && !isFetching && user_data?.isAdmin;
    return (
        <Navbar theme={CustomNavbarTheme} fluid rounded className="px-3">
            <Navbar.Brand href="/">
                <img src="/assets/images/fdblock.png" className="mr-3 h-6 sm:h-9" alt="fdblock.org logo" />
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">fdblock.org</span>
            </Navbar.Brand>
            <div className="flex md:order-2">
                <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                        <span className="flex flex-row space-x-4 justify-center items-center content-center">
                            {SignedIn() ? (
                                <>
                                    <span className="text-2xl">
                                        <FaRegUserCircle />
                                    </span>
                                    <span className="lg:flex hidden lg:flex-col md:flex-row items-end">
                                        <span>Signed in as&nbsp;</span>
                                        <span>{user_phone_number}</span>
                                    </span>
                                </>
                            ) : null}
                        </span>
                    }
                >
                    <>
                        <NavLink to="dashboard/profile">
                            <Dropdown.Item>My Profile</Dropdown.Item>
                        </NavLink>
                        <NavLink to="dashboard/bookings">
                            <Dropdown.Item>My Bookings</Dropdown.Item>
                        </NavLink>
                        <NavLink to="dashboard/booking-requests">
                            <Dropdown.Item>Booking Approvals</Dropdown.Item>
                        </NavLink>
                        <Dropdown.Divider />
                        <NavLink to="dashboard/block-directory">
                            <Dropdown.Item>FD Block Directory</Dropdown.Item>
                        </NavLink>
                        <Dropdown.Divider />
                        {signed_in_user_is_admin ? (
                            <>
                                <span className="my-4 px-4 text-sm block font-bold">Admin Section</span>
                                <NavLink to="admin/bookings">
                                    <Dropdown.Item>All Bookings</Dropdown.Item>
                                </NavLink>
                                <NavLink to="admin/users">
                                    <Dropdown.Item>All Users</Dropdown.Item>
                                </NavLink>
                                <NavLink to="admin/users/create-new">
                                    <Dropdown.Item>New User</Dropdown.Item>
                                </NavLink>
                                <Dropdown.Divider />
                            </>
                        ) : null}
                        <Dropdown.Item onClick={() => SignOut()}>Logout</Dropdown.Item>
                    </>
                </Dropdown>
                <Navbar.Toggle />
            </div>
            <Navbar.Collapse>
                {navbar_elements.map(elem => {
                    // Since "/" is a part of all the routes, we have to check for its equality separately
                    // const active_status = (elem.route !== "/" && location.pathname.includes(elem.route)) || (elem.route === "/" && location.pathname === elem.route);
                    const active_status = elem.route === location.pathname;
                    return (
                        <NavLink to={elem.route} key={Math.random()}>
                            <Navbar.Link active={active_status}>{elem.title}</Navbar.Link>
                        </NavLink>
                    );
                })}
                {isFetching ? (
                    <span className="flex flex-col justify-center">
                        <Spinner color="info" />
                    </span>
                ) : !SignedIn() ? (
                    <NavLink to={"login"}>
                        <Navbar.Link>
                            <span className="">Login</span>
                        </Navbar.Link>
                    </NavLink>
                ) : null}
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
